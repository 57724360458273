<template>
  <div>
    <div class="check-info">
      <!--判断是从哪里点击进来的,main是从未提交作业-->
      <div class="info-tabs" v-if="originType === 'main'">
        <span
          class="tabs-btn"
          v-for="(item, index) in checkData"
          :key="index"
          :class="{
            'tabs-btn-first': item.sort === 0,
            'tabs-btn-tail': item.sort === checkData.length - 1,
            'tabs-btn-firsted': sortValue === 0 && item.sort === 0,
            'tabs-btn-tailed': sortValue === checkData.length - 1 && item.sort === checkData.length - 1,
            'tabs-active': sortValue !== 0 && sortValue !== checkData.length - 1 && sortValue === item.sort,
          }"
          @click="ontabs(item)"
          :title="item.name"
          >{{ item.name }}</span
        >
      </div>
      <div v-if="tabData">
        <div class="link-name">
          <p>
            环节名称：<span>{{ tabData.name }}</span> 环节类型：<span>{{
              tabData.exerciseType === 'INTERACTIVE_EXERCISE'
                ? '互动练习'
                : tabData.questionTopicType === 'SUBJECTIVE_EXERCISE'
                ? '主观练习'
                : '客观练习'
            }}</span>
            <!-- <a-button
              class="change-serious"
              @click="changeSerious"
              v-if="
                scheduleData.scheduleStatus !== 'CANCELLED' &&
                tabData.exerciseType === 'SUBJECTIVE_EXERCISE' &&
                tabData.exercises[0].type === 'GENERAL_SUBJECTIVE' &&
                uuid === teacherId
              "
              :disabled="studentReview.publish"
              >修改</a-button
            > -->
          </p>
        </div>
        <div>
          <div
            v-if="
              tabData.exerciseType === 'INTERACTIVE_EXERCISE' ||
              (tabData.questionTopicType === 'OBJECTIVE_EXERCISE' && tabData.exerciseType !== 'SERIOUS_EXERCISE')
            "
          >
            <div class="question-list">
              <!-- 阅读题 -->
              <div class="question-item read-aloud">
                <div v-for="i in homeWorkList" :key="i.uuid" style="margin-bottom: 20px">
                  <!-- 朗读题 -->
                  <PictureBook v-if="i.type === 'PICTURE_BOOK'" v-bind="i" />
                  <!-- 朗读题 -->
                  <PictureSpeak v-if="i.type === 'pictureSpeak'" v-bind="i" />
                  <!-- 选择题 -->
                  <!-- <Choice v-if="i.type === 'CHOICE'" v-bind="i" /> -->
                  <Exercise
                    v-if="i && i[0] && i[0].nodes && i[0].nodes[0].type === 'choice'"
                    :segments="i"
                    segmentId="Segment1"
                  />
                  <!-- 填空题 -->
                  <BlankFilling v-if="i.type === 'BLANK_FILLING'" v-bind="i" />
                  <!-- 连线题 -->
                  <Connection v-if="i.type === 'CONNECTION'" v-bind="i" />
                  <!-- 带解析选择题 -->
                  <div v-if="Array.isArray(i) && i[0].type === 'explanChoice'" class="explan-choice">
                    <ExplanChoice v-bind="i[0]" :showExplan="showExplanId === i[0].id" />
                    <a-button size="large" class="explan-choice-button" @click="onShowExplan(i[0].id)"
                      >Problem analysis</a-button
                    >
                  </div>
                  <GeneralSubjective v-if="i.type === 'generalSubjective'" v-bind="i" />

                  <!-- 点选题 -->
                  <PointSelect v-if="i.type === 'pointSelect'" v-bind="i" />
                  <!-- 写字练习 -->
                  <WriteChar v-if="i.type === 'writeChar'" v-bind="i" />
                  <!-- 文字补全 -->
                  <ArticleCompletion v-if="i.type === 'ARTICLE_FILLING'" v-bind="i" />
                  <!-- 排序 -->
                  <Sort v-if="i.type === 'SORTING_QUESTION'" v-bind="i" />
                  <!--数学填空题带解析-->
                  <div v-if="i.type === 'mathFill'" class="explan-choice">
                    <MathFill v-bind="i" :showExplan="showExplanId === i.id" v-if="i.type === 'mathFill'" />
                    <a-button size="large" class="explan-choice-button" @click="onShowExplan(i.id)"
                      >Problem analysis</a-button
                    >
                  </div>
                  <!-- <MathFill v-if="i.type === 'mathFill'" v-bind="i" /> -->
                  <!-- 自动补全 -->
                  <Completion v-if="i.type === 'completion'" v-bind="i" />
                  <!-- 自动排序 -->
                  <Sequence v-if="i.type === 'sequence'" v-bind="i" />
                  <!-- 自动选择 -->
                  <ChoiceV3 v-if="i.type === 'choiceV3'" v-bind="i" />
                  <!-- 自动写字 -->
                  <WriteChar3 v-if="i.type === 'writeCharV3'" v-bind="i" />
                  <!--配对题-->
                  <Ligature v-if="i.type === 'ligature'" v-bind="i" />
                  <!-- 打字题-全部 -->
                  <Typewrite v-if="i.type === 'typewrite'" v-bind="i" />
                  <!-- 打字填空题  -->
                  <TypewriteFill v-if="i.type === 'typewriteFill'" v-bind="i" />
                  <!-- 朗读题  -->
                  <Recitation v-if="i.type === 'recitation'" v-bind="i" />
                  <!--数学填空题-->
                  <MathComplete v-if="i.type === 'mathComplete'" v-bind="i" />
                  <!--数学选择题-->
                  <MathChoice v-if="i.type === 'mathChoice'" v-bind="i" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              tabData.exerciseType === 'SUBJECTIVE_EXERCISE' ||
              tabData.exerciseType === 'COMPLEX_SUBJECTIVE' ||
              (tabData.questionTopicType === 'SUBJECTIVE_EXERCISE' && tabData.exerciseType !== 'SERIOUS_EXERCISE')
            "
          >
            <div class="question-item read-aloud">
              <div v-for="(i, index) in homeWorkList" :key="index" style="margin-bottom: 20px">
                <!-- 主观题 -->
                <Subjective v-bind="i" v-if="i[0].type === 'SUBJECTIVE'" />
                <!-- 通用主观题 -->
                <!-- <Exercise :segments="i" segmentId="Segment1" /> -->
                <GeneralSubjective v-if="i[0].type === 'generalSubjective'" v-bind="JSON.parse(JSON.stringify(i))" />

                <!-- 写字主观题 -->
                <WriteSubjective
                  v-if="
                    i[0] &&
                    (i[0].type === 'writeSubjective' ||
                      i[0].type === 'drawingSubjective' ||
                      i[0].type === 'colloquialSubjective' ||
                      i[0].type === 'readAloudSubjective' ||
                      i[0].type === 'videoSubject')
                  "
                  v-bind="i"
                />
              </div>
            </div>
          </div>
          <div v-else-if="tabData.exerciseType === 'SERIOUS_EXERCISE'">
            <div class="question-item read-aloud">
              <div v-for="(i, index) in homeWorkList" :key="index" style="margin-bottom: 20px">
                <!-- 严肃练习 -->
                <Exercise :segments="i" segmentId="Segment1" />
              </div>
            </div>
          </div>
          <div v-else-if="tabData.exerciseType === 'READER_EXERCISE'">
            <div class="question-item read-aloud">
              <div v-for="(i, index) in homeWorkList" :key="index" style="margin-bottom: 20px">
                <!-- 绘本2.0 -->
                <Reader v-bind="i" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loading" v-if="showPopover">
        <a-spin size="large" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  // Choice,
  PictureBook,
  BlankFilling,
  Connection,
  Subjective,
  ExplanChoice,
  Exercise,
  withIframe,
  GeneralSubjective,
  PointSelect,
  WriteChar,
  ArticleCompletion,
  Sort,
  MathFill,
  PictureSpeak,
  WriteSubjective,
  Completion,
  Sequence,
  ChoiceV3,
  WriteChar3,
  Ligature,
  Typewrite,
  TypewriteFill,
  Recitation,
  MathComplete,
  MathChoice,
  Reader,
} from '@wk/iexercise';
import storage from 'store';
import moment from 'moment';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';
// import SeriousForm from './SeriousForm';
import { UUID } from '@/store/mutation-types';

export default {
  components: {
    // SeriousForm,
    // Choice: withIframe(Choice),
    PictureBook: withIframe(PictureBook),
    PictureSpeak: withIframe(PictureSpeak),
    BlankFilling: withIframe(BlankFilling),
    Connection: withIframe(Connection),
    Subjective: withIframe(Subjective),
    ExplanChoice: withIframe(ExplanChoice),
    Exercise: withIframe(Exercise),
    GeneralSubjective: withIframe(GeneralSubjective),
    PointSelect: withIframe(PointSelect),
    WriteChar: withIframe(WriteChar),
    ArticleCompletion: withIframe(ArticleCompletion),
    Sort: withIframe(Sort),
    MathFill: withIframe(MathFill),
    WriteSubjective: withIframe(WriteSubjective),
    Completion: withIframe(Completion),
    Sequence: withIframe(Sequence),
    ChoiceV3: withIframe(ChoiceV3),
    WriteChar3: withIframe(WriteChar3),
    Ligature: withIframe(Ligature),
    Typewrite: withIframe(Typewrite),
    TypewriteFill: withIframe(TypewriteFill),
    Recitation: withIframe(Recitation),
    MathComplete: withIframe(MathComplete),
    MathChoice: withIframe(MathChoice),
    Reader: withIframe(Reader),
  },
  props: {
    originType: {
      type: String,
      default: 'main',
    },
    checkData: {
      type: Array,
      default: () => [],
    },
    studentReview: {
      type: Object,
      default: () => {},
    },
    scheduleData: {
      type: Object,
      default: () => {},
    },
    homeworkTemplate: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uuid: storage.get(UUID),
      // loadingBol: false,
      visible: true,
      sortValue: 0,
      tabData: undefined,
      homeWorkList: [],
      showPopover: true,
      showExplanId: '',
      seriousVisible: false,
      saveLoad: false,
    };
  },
  watch: {
    checkData: {
      handler() {
        setTimeout(() => {
          if (this.tabData.exerciseType === 'SERIOUS_EXERCISE') {
            this.initSeriousCompont(this.tabData);
          } else {
            this.initCompont(this.tabData.exercises);
          }
        }, 200);
        this.showPopoverTime();
        if (this.originType !== 'main') {
          // 代表是从未提交过来
          this.ontabs(this.checkData[0]);
        } else {
          this.ontabs(this.checkData[this.sortValue]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    [this.tabData] = this.checkData;
    setTimeout(() => {
      if (this.tabData.exerciseType === 'SERIOUS_EXERCISE') {
        this.initSeriousCompont(this.tabData);
      } else {
        this.initCompont(this.tabData.exercises);
      }
    }, 200);
    this.showPopoverTime();
  },
  methods: {
    moment,
    saveData(data) {
      this.checkData.forEach((item, index) => {
        if (item.uuid === data.saveData.uuid) {
          this.checkData[index] = data.saveData;
        }
      });
      this.homeworkTemplate.homeworkLink = this.checkData;
      const datas = {
        courseScheduleId: this.$route.query.uuid,
        courseSectionId: this.scheduleData.courseSectionId,
        homework: this.homeworkTemplate,
      };
      this.saveLoad = true;
      this.$post('/api/homework/bak', datas)
        .then((res) => {
          if (res.status === 200) {
            if (data.type) {
              this.seriousVisible = false;
              this.publish(datas);
            } else if (data.batch) {
              this.seriousVisible = true;
              this.saveLoad = false;
            } else {
              this.seriousVisible = false;
              this.saveLoad = false;
              this.$emit('initCourseHomeWork');
            }
          }
        })
        .finally(() => {
          this.saveLoad = false;
        });
    },
    publish(datas) {
      this.$post('/api/homework/bak/publish', datas).then(() => {
        this.$message.success('发布成功');
        this.saveLoad = false;
        this.$emit('initCourseHomeWork');
      });
    },
    // 修改主观题
    changeSerious() {
      this.$emit('onShowSeriousForm');
      // this.seriousVisible = true;
    },
    onShowExplan(id) {
      this.showExplanId = this.showExplanId === id ? '' : id;
    },
    showPopoverTime() {
      setTimeout(() => {
        this.showPopover = false;
      }, 1000);
    },
    ontabs(values) {
      this.showExplanId = '';
      this.tabData = values;
      this.sortValue = this.tabData.sort;
      if (values.exerciseType === 'SERIOUS_EXERCISE') {
        this.initSeriousCompont(values);
      } else {
        this.initCompont(values.exercises);
      }
      this.showPopover = true;
      this.showPopoverTime();
    },
    initSeriousCompont(datas) {
      this.homeWorkList = [];
      switch (datas.exerciseType) {
        case 'SERIOUS_EXERCISE':
          this.serious(datas);
          break;
        default:
          break;
      }
    },
    initCompont(datas) {
      this.homeWorkList = [];
      datas.forEach((item, i) => {
        switch (item.type) {
          case 'MATH_FILLING':
            this.homeWorkList.push(this.blankFillingMathData(item));
            break;
          case 'PICTURE_BOOK':
            this.homeWorkList.push(this.pictureBookData(item));
            break;
          case 'READING_QUESTION':
            this.homeWorkList.push(this.readingQuestion(item));
            break;
          case 'CHOICE':
            this.homeWorkList.push(this.choiceData(item));
            break;
          case 'BLANK_FILLING':
            this.homeWorkList.push(this.blankFillingData(item));
            break;
          case 'CONNECTION':
            this.homeWorkList.push(this.connectionData(item));
            break;
          case 'SUBJECTIVE':
            this.homeWorkList.push([this.subjective(item)]);
            break;
          case 'EXPLAN_CHOICE':
            this.homeWorkList.push([this.explan(item)]);
            break;
          case 'GENERAL_SUBJECTIVE':
            this.homeWorkList.push([this.generalSubjective(item)]);
            // this.homeWorkList.push(this.generalSubjective(item));
            break;
          case 'CLICK_SELECT':
            this.homeWorkList.push(this.pointSelect(item));
            break;
          case 'WRITE_QUESTION':
            this.homeWorkList.push(this.writeChar(item));
            break;
          case 'ARTICLE_FILLING':
            this.homeWorkList.push(this.articleFilling(item));
            break;
          case 'SORTING_QUESTION':
            this.homeWorkList.push(this.sort(item));
            break;
          case 'WRITE_SUBJECTIVE':
            this.homeWorkList.push([this.WriteSubjective(item)]);
            break;
          case 'DRAWING_SUBJECTIVE':
            this.homeWorkList.push([this.WriteSubjective(item)]);
            break;
          case 'COLLOQUIAL_SUBJECTIVE':
            this.homeWorkList.push([this.WriteSubjective(item)]);
            break;
          case 'READ_ALOUD_SUBJECTIVE':
            this.homeWorkList.push([this.WriteSubjective(item)]);
            break;
          case 'VIDEO_SUBJECTIVE':
            this.homeWorkList.push([this.WriteSubjective(item)]);
            break;
          case 'AUTO_FILLING':
            this.homeWorkList.push(this.autoFillingData(item));
            break;
          case 'AUTO_SORTING':
            this.homeWorkList.push(this.autoSortingData(item));
            break;
          case 'AUTO_WRITE':
            this.homeWorkList.push(this.autoWriteData(item));
            break;
          case 'AUTO_CHOICE':
          case 'AUTO_ADV_CHOICE':
            this.homeWorkList.push(this.autoChoiceData(item));
            break;
          case 'AUTO_MATCHING':
            this.homeWorkList.push(this.ligatureData(item));
            break;
          case 'AUTO_INPUT':
            this.homeWorkList.push(this.typewriteData(item));
            break;
          case 'AUTO_INPUT_FILLING':
            this.homeWorkList.push(this.typewriteFillData(item));
            break;
          case 'AUTO_READING':
            this.homeWorkList.push(this.newReadingQuestions(item));
            break;
          case 'MATH_CHOICE':
            this.homeWorkList.push(this.blankChoiceMathData(item));
            break;
          case 'MATH_COMPLETE':
            this.homeWorkList.push(this.blankCompleteMathData(item));
            break;
          case 'READ_BOOK':
            this.homeWorkList.push(this.makeReader(item));
            break;
          default:
            break;
        }
      });
    },
    serious(datas) {
      datas.exercises.forEach((item) => {
        const node = [];
        const o = {
          title: '做一做',
          type: '复习巩固',
          id: 'Segment1',
          status: 'UN_FINISHED',
          cover: '/demo/segment-cover-1.png',
          paper: true,
        };
        switch (item.type) {
          case 'PICTURE_BOOK':
            node.push(this.pictureBookData(item));
            break;
          case 'READING_QUESTION':
            node.push(this.readingQuestion(item));
            break;
          case 'CHOICE':
            node.push(this.choiceData(item));
            break;
          case 'BLANK_FILLING':
            node.push(this.blankFillingData(item));
            break;
          case 'CONNECTION':
            node.push(this.connectionData(item));
            break;
          case 'SUBJECTIVE':
            node.push(this.subjective(item));
            break;
          case 'EXPLAN_CHOICE':
            node.push(this.explan(item));
            break;
          case 'SINGLE_CHOICE':
            node.push(this.explanSingle(item));
            break;
          default:
            break;
        }
        o.nodes = node;
        this.homeWorkList.push([o]);
      });
    },
    subjective(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'SUBJECTIVE',
        payload: {
          picture: data.contentOption.picture,
          status: 'finished',
          preview: true,
          task: data.contentOption.task,
          steps: data.contentOption.steps,
          homework: {},
        },
      };
    },
    connectionData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'CONNECTION',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    // 选词填空
    blankFillingData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'BLANK_FILLING',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    blankCompleteMathData(data) {
      // 多步骤数学填空题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },

        type: 'mathComplete',
        payload: {
          ...data?.contentOption,
        },
      };
    },
    makeReader(data, page) {
      return {
        type: 'reader',
        id: data.uuid,
        pageSpan: page || 1,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        payload: [
          {
            id: data.uuid,
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
            insertValues: data.contentOption.insertValues,
          },
        ],
      };
    },
    blankChoiceMathData(data) {
      // 查看数学选择题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },

        type: 'mathChoice',
        payload: {
          question: {
            content: data.contentOption?.title,
            audio: data.contentOption?.audio,
            difficulty: data.contentOption?.difficulty,
            pictures: data.contentOption?.pictures,
          },
          explan: {
            content: data.contentOption?.explan,
            audio: data.contentOption?.explanAudio,
            video: data.contentOption?.explanVideo,
          },
          operation: {
            options: data.contentOption?.options?.map((item) => ({
              ...item,
              content: transformStringToDelta(item.content),
            })),
            answer: data.contentOption?.answer,
          },
        },
      };
    },
    blankFillingMathData(data) {
      // 查看数学填空题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },

        type: 'mathFill',
        payload: {
          keyboardId: 'normal',
          answers: data.contentOption.answers,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          pictures: [data.contentOption.picture, ...data.contentOption.pictures].filter((i) => i),
          explan: data.contentOption.explan,
        },
      };
    },
    // 选择题
    choiceData(data) {
      // return {
      //   id: data.uuid,
      //   title: {
      //     content: data.title,
      //     audio: data.audio,
      //   },
      //   tag: data.tag,
      //   type: 'CHOICE',
      //   payload: data.contentOption,
      // };
      return [
        {
          title: '读一读',
          type: '复习巩固',
          id: 'Segment1',
          status: false,
          cover: '/demo/segment-cover-1.png',
          nodes: [
            {
              id: data.uuid,
              title: {
                content: data.title,
                audio: '',
                enContent: data.enTitle,
                enAudio: data.enAudio,
              },
              tag: data.tag,
              type: 'choice',
              payload: data.contentOption,
            },
          ],
        },
      ];
    },
    // 绘本
    pictureBookData(data) {
      return {
        type: 'PICTURE_BOOK',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        pageSpan: 1,
        tag: data.tag,
        payload: [
          {
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
          },
        ],
      };
    },
    // 朗读题 READING_QUESTION
    readingQuestion(data) {
      return {
        type: 'pictureSpeak',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          id: data.uuid,
          picture: data.contentOption.picture,
          audio: data.contentOption.bookAudio,
          text: data.contentOption.text,
          pinyins: data.contentOption.pinyins,
        },
        hideTooltip: true,
      };
    },
    // 带解析的选择题
    explan(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          pictureType: data.contentOption.picturesOptions,
          //  picture: data.contentOption.picture,
          explan: data.contentOption.explan,
          //   pictures: [],
          pictures: [...(data.contentOption?.pictures || []), data.contentOption?.picture].filter((item) => !!item),
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 单选题
    explanSingle(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        // payload: data.contentOption,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          explan: data.contentOption.explan,
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 写字主观题

    WriteSubjective(data) {
      let type = '';
      let url = '';
      let audioUrl;
      if (data.type === 'WRITE_SUBJECTIVE') {
        type = 'writeSubjective';
        url = data.contentOption.copybooks.copybookUrl;
      } else if (data.type === 'DRAWING_SUBJECTIVE') {
        type = 'drawingSubjective';
        url = data.contentOption.pictures;
      } else if (data.type === 'COLLOQUIAL_SUBJECTIVE') {
        type = 'colloquialSubjective';
        url = data.contentOption.pictures;
      } else if (data.type === 'READ_ALOUD_SUBJECTIVE') {
        type = 'readAloudSubjective';
        url = data.contentOption.attachment;
        audioUrl = data.contentOption.audio;
      } else if (data.type === 'VIDEO_SUBJECTIVE') {
        type = 'videoSubject';
      }
      return {
        type,
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          question:
            type === 'videoSubject'
              ? { url: data.contentOption.video, cover: data.contentOption.picture }
              : { url, audioUrl },
          review: true,
        },
      };
    },
    // 通用主观题
    generalSubjective(data) {
      // return [
      //   {
      //     title: '读一读',
      //     type: '复习巩固',
      //     id: 'Segment2',
      //     status: false,
      //     cover: '/demo/segment-cover-1.png',
      //     nodes: [
      //       {
      //         type: 'generalSubjective',
      //         id: data.uuid,
      //         title: {
      //           content: data.title,
      //           audio: data.audio,
      //         },
      //         tag: data.tag,
      //         payload: {
      //           status: 'finished',
      //           question: Object.assign(data.contentOption, { title: data.title }),
      //           answer: {},
      //         },
      //       },
      //     ],
      //   },
      // ];
      return {
        type: 'generalSubjective',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          status: 'finished',
          question: Object.assign(data.contentOption, { title: data.title }),
          // answer: {},
        },
      };
    },
    // 点选题
    pointSelect(data) {
      return {
        type: 'pointSelect',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 写字练习
    writeChar(data) {
      return {
        type: 'writeChar',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: Object.assign(data.contentOption, { clearDefaultShow: false }),
      };
    },
    // 文章补全题
    articleFilling(data) {
      return {
        type: 'ARTICLE_FILLING',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 排序
    sort(data) {
      return {
        type: 'SORTING_QUESTION',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 自动补全题
    autoFillingData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'completion',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 自动排序
    autoSortingData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'sequence',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 自动写字
    autoWriteData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'writeCharV3',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 自动选择
    autoChoiceData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'choiceV3',
        payload: {
          question: {
            ...data?.contentOption?.autoLeft,
            text: { pinyins: text?.pinYins, value: text?.value },
            isSenior: data.type === 'AUTO_ADV_CHOICE',
          },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 配对题
    ligatureData(data) {
      const { text } = data?.contentOption?.autoLeft || {};
      const options = data?.contentOption?.autoRight;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'ligature',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: {
            ...options,
            begin: options.begin?.map((i) => {
              if (i.type === 'picture') {
                return {
                  ...i,
                  type: 'pic',
                };
              }
              return {
                ...i,
              };
            }),
            end: options.end?.map((i) => {
              if (i.type === 'picture') {
                return {
                  ...i,
                  type: 'pic',
                };
              }
              return {
                ...i,
              };
            }),
          },
        },
      };
    },
    // 打字题-全部
    typewriteData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'typewrite',
        payload: {
          question: {
            ...data?.contentOption?.autoLeft,
            type: data?.contentOption?.autoLeft.type === 'picture' ? 'pic' : data?.contentOption?.autoLeft.type,
          },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 打字填空题
    typewriteFillData(data) {
      const questionData = { ...data?.contentOption?.autoLeft, showPy: data?.contentOption?.autoLeft?.show_py };
      delete questionData.show_py;
      const operationData = { ...data?.contentOption?.autoRight };
      delete operationData.pinyinList;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'typewriteFill',
        payload: {
          question: {
            ...questionData,
          },
          operation: operationData,
        },
      };
    },
    // 新朗读题
    newReadingQuestions(data) {
      const questionData = { ...data?.contentOption?.autoLeft };

      const operationData = { ...data?.contentOption?.autoRight };
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'recitation',
        payload: {
          question: {
            ...questionData,
          },
          operation: operationData,
        },
      };
    },
    closeBack() {
      this.seriousVisible = false;
    },
    onClose() {
      this.$emit('cancal');
    },
  },
};
</script>
<style lang="less" scoped>
.check-info {
  .change-serious {
    width: 82px;
    height: 30px;
    border-radius: 8px;
    background-color: #009cff;
    color: #fff;
  }
  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
  .line {
    height: 1px;
    border: none;
    background-color: #009cff;
    margin-top: -15px;
  }
  .info-tabs {
    display: flex;
    justify-content: flex-start;
    .tabs-btn {
      display: inline-block;
      width: 100px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      text-align: center;
      color: #999;
      background-size: 100% 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: url(~@/assets/tab_part2_no.png) no-repeat center;
      background-size: 100% 100%;
      &:hover {
        cursor: pointer;
      }
    }
    .tabs-btn-first {
      background: url(~@/assets/tab_part1_no.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-firsted {
      color: #fff;
      background: url(~@/assets/tab_part1.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-tail {
      background: url(~@/assets/tab_part3_no.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-btn-tailed {
      color: #fff;
      background: url(~@/assets/tab_part3.png) no-repeat center;
      background-size: 100% 100%;
    }
    .tabs-active {
      color: #fff;
      background: url(~@/assets/tab_part2.png);
      background-size: 100% 100%;
    }
  }
  .link-name {
    background: #fafafa;
    border-radius: 10px;
    padding: 0 20px;
    margin: 30px 0;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 54px;
      color: #666;
      span {
        color: #333;
        font-weight: 600;
        margin-right: 40px;
      }
    }
  }
  .question-type {
    color: #333;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      line-height: 14px;
      font-weight: normal;
      padding: 2px 10px;
      border-radius: 6px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      margin-left: 10px;
    }
  }
  .question-list {
    overflow-y: auto;
    .question-item {
      border-top: 1px solid #eee;
      padding: 20px 0;
    }
    p {
      span {
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        padding: 2px 10px;
        border-radius: 6px;
        border: 1px solid rgba(151, 151, 151, 0.3);
        margin-left: 10px;
      }
    }
  }
  .read-aloud {
    p {
      color: #333;
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 10px;
    }
    img {
      width: 320px;
      height: 160px;
    }
  }
  .loading {
    width: 100%;
    height: calc(100vh - 120px);
    background-color: rgba(255, 255, 255, 1);
    padding: 50% 0 0 0;
    text-align: center;
    line-height: 100%;
    font-size: 60px;
    position: absolute;
    bottom: 0;
    right: 20px;
    z-index: 9999;
  }
  .explan-choice {
    position: relative;
    .explan-choice-button {
      border-radius: 30px;
      position: absolute;
      bottom: 0px;
      right: 0;
      z-index: 100000;
    }
  }
}
</style>

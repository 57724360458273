<template>
  <!--约课任务-->
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span
              ><span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
            <div v-if="taskType === 'TASK_TRACK_CREATE'">
              【完成方式】电话/文字沟通<br />
              【完成时效】5天<br />
              【操作步骤】<br />
              1、沟通中：<br />
              a.先给再要：如有遗留异议先行解决家长异议；如无异议可借用学习反馈、学习资料等做话题铺垫<br />
              b.告知家长拓科活动，并询问学员是否有学习数学的需求<br />
              c.询问家长是否同意注册试听：是/否，询问不试听原因<br />
              2、沟通后：<br />
              a.若家长同意注册试听，发送拓科海报让家长扫码预约<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />
              【注意事项】<br />
              ①如用户未明确拒绝/不回复，需连续跟进3天，跟进当天至少触达1次，方可完成任务<br />
              ②跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
            <div v-if="taskType === 'TASK_SUBSCRIBE_COURSE'">
              【完成方式】电话/文字沟通<br />
              【完成时效】5天<br />
              【操作步骤】<br />
              1、沟通中：<br />
              a.先给再要：如有遗留异议先行解决家长异议；如无异议可借用学习反馈、学习资料等做话题铺垫<br />
              b.邀请家长进行试听<br />
              c.询问家长是否同意约课：已约/未约，询问不约课原因<br />
              2、沟通后：<br />
              a.若家长同意约课试听，通过花果的【约课任务】中的约课链接完成约课或者复制约课链接发给家长，邀请家长自行约课<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />
              <br />
              【注意事项】<br />
              ①如用户未明确拒绝/不回复，需连续跟进3天，跟进当天至少触达1次，方可完成任务<br />
              ②约课后如需改约，及时查看线索所有人==顾问，更改上课时间<br />
              ③跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>

        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? formInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span :class="formInfoData.expireFlag ? 'dueDate' : ''">{{ formInfoData.dueDate }}</span>
      </div>
      <!--约课任务-->
      <div class="form-desc" v-if="taskType === 'TASK_SUBSCRIBE_COURSE'">
        请及时去完成【数学】线索的约课 <span class="clue-txt" @click="hrefClue">关联线索<a-icon type="right" /></span>
      </div>
      <!--线索创建-->
      <div class="form-desc" v-if="taskType === 'TASK_TRACK_CREATE'">
        请及时去跟进学员，完成{{
          formInfoData.question.substring(formInfoData.question.indexOf('【') + 1, formInfoData.question.indexOf('】'))
        }}线索进线
      </div>
    </div>
    <div style="margin-top: 20px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" style="display: flex" @click="onStudentId(item)">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name }} ({{ item.code }})>
              </p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    // 任务类型
    taskType: {
      type: String,
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,

      showFlag: false,
    };
  },
  methods: {
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcnFMSHCKyi09oqCuPfCxeuCh', '_blank');
    },
    // 点击跳转线索详情
    hrefClue() {
      if (this.formInfoData.studentArray.length > 0) {
        const data = this.formInfoData.studentArray[0];
        this.$emit('onHrefClue', { name: data.name, id: data.studentId, code: data.code });
      }
    },
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.clue-txt {
  font-size: 16px;
  padding-left: 19px;
  cursor: pointer;
  color: rgb(51, 114, 254);
  margin-bottom: 15px;
}
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-desc {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
  padding-left: 28px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  p {
    margin-left: 8px;
  }
}
.reportPhase {
  display: flex;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.icon-starlight {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star_ed.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
.icon-star {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
/deep/ .ant-form label {
  font-size: 16px !important;
}
.study-wrap {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 14px;
  color: #333;
  margin: 15px 0;
  border-radius: 5px;
  background-color: #fafafa;
  cursor: pointer;
}
.study-txt {
  font-size: 14px;
  margin-top: 5px;
}
.study-top {
  display: flex;
  justify-content: space-between;
}
.study-btnWrap {
  font-size: 14px;
  color: #009cff;
}
.download-poster {
  margin-right: 15px;
  cursor: pointer;
}
.send-group {
  cursor: pointer;
}
.consult-img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-ulWrapTC {
  max-width: 600px;
  list-style: disc;
  padding-left: 20px;
  color: #009cff;
  span {
    color: #333;
    font-size: 16px;
  }
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>

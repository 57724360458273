<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span>
              <!-- <span class="task-explain" @click="lookWord">查看文档说明</span> -->
            </div>
            <div v-if="formInfoData.subjectType === 'TASK_STUDENT_ABSENT_CHINESE'">
              【完成方式】电话/文字沟通<br />
              【完成时效】7天<br />
              【沟通思路】<br />
              ➊了解学员旷课原因<br />
              a.忘记请假<br />
              ➜私教课： 提前2H请假不扣减课时<br />
              ➜班课：告知调课规则，可以看回放<br />
              b.忘记时间<br />
              1. 提醒家长打开WuKong Class APP的通知，APP会在课前24H，1H，5min推送上课提醒<br />
              2. 学员迟到悟空会以电话形式致电提醒上课<br />
              ➋确认现在的上课时间是否需要调整<br />
              ➌补课安排：询问学员何时方便补课，及时跟上课程进度<br />
              【注意事项】跟进记录中的每一项均需沟通完毕才可完成任务,不得在未沟通的情况下点击完成任务<br />
            </div>
            <div
              v-if="
                formInfoData.subjectType === 'TASK_STUDENT_ABSENT_MATH' ||
                formInfoData.subjectType === 'TASK_STUDENT_ABSENT_ENGLISH'
              "
            >
              任务说明<br/>
              【完成方式】电话/文字沟通<br/>
              【完成时效】7天<br/>
              【沟通思路】<br/>
              ➊了解学员旷课原因<br/>
              a.忘记请假<br/>
              ➜私教课： 提前2H请假不扣减课时<br/>
              ➜班课：告知调课规则，可以看回放<br/>
              b.忘记时间<br/>
              1. 提醒家长打开WuKong Class APP的通知，APP会在课前24H，1H，5min推送上课提醒<br/>
              2. 学员迟到悟空会以电话形式致电提醒上课<br/>
              ➋确认现在的上课时间是否需要调整<br/>
              ➌补课安排：询问学员何时方便补课，及时跟上课程进度<br/>
              【注意事项】跟进记录中的每一项均需沟通完毕才可完成任务,不得在未沟通的情况下点击完成任务<br/>
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? ormInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div class="form-des">
        <p>请尽快跟进该学员的旷课情况，并完成该任务的跟进记录</p>
      </div>
    </div>

    <div style="margin-top: 15px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" @click="onStudentId(item)" style="display: flex">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name }} ({{ item.code }})>
              </p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="courseInfo" style="border-top: 1px solid #e9e9e9">
      <div class="title">
        <span>缺勤课节</span>
      </div>
      <!-- <p class="class-name" style="margin-bottom: 5px;margin-top:15px;padding-top:15px;border-top: 1px solid #e9e9e9;"></p> -->
      <div class="course-info">
        <span class="course-name"
          >{{ formInfoData.absentCourseInfo.scheduleName }} （{{ formInfoData.absentCourseInfo.teacherName }}）</span
        >
        <div class="course-type">
          <span
            >旷课日期：{{ formInfoData.absentCourseInfo.startDateTime }} -
            {{ formInfoData.absentCourseInfo.endDateTime.substring(10) }}</span
          >
        </div>
        <div class="course-type">
          <span>授课老师：{{ formInfoData.absentCourseInfo.teacherName }}</span>
        </div>
        <div class="course-type">
          <span>{{ formInfoData.absentCourseInfo.courseType === 'PRIVATE' ? '私教课' : '班课' }}</span> |
          <span>班级：{{ formInfoData.absentCourseInfo.className }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      pictures: [],
    };
  },
  methods: {
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 15px 0 0;
  margin-top: 15px;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.class-name {
  color: #333;
  font-size: 16px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>

<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip
          :overlayStyle="{ maxWidth: '500px' }"
          v-if="formInfoData.subDetailType === 'classAdmin' && detailType === 'CHINESE_RESIGN_TAKE_OVER'"
        >
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
          </template>
          <i
            class="explainTC"
            v-if="formInfoData.subDetailType === 'classAdmin' && detailType === 'CHINESE_RESIGN_TAKE_OVER'"
          ></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        任务生成于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>

      <div class="form-desc form-desc1">
        <span>反馈学员：</span>
        <p>{{ formInfoData.studentName }}</p>
        ({{ formInfoData.studentCode }})
      </div>
      <div class="form-desc form-desc1">
        <span>反馈课节：</span>
        <p>
          {{ formInfoData.courseScheduleName }} (班级时区：{{
            formInfoData.courseScheduleStartDateTime
              ? $moment(formInfoData.courseScheduleStartDateTime).format('YYYY-MM-DD HH:mm')
              : '-'
          }})
        </p>
        <a @click="goToReplay" style="margin-left: 4px; white-space: nowrap">查看课节回放</a>
      </div>
      <div class="form-desc form-desc1">
        <span>反馈发起人：</span>
        <p>{{ formInfoData.startUserName }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>反馈分类：</span>
        <p>{{ CategoryEnum[formInfoData.teachingFeedbackCategory] }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>反馈原因：</span>
        <p>{{ formInfoData.reasonList.join('、') }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>是否申请课时返还：</span>
        <p>{{ formInfoData.cuReturn ? '是' : '否' }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>课时数量：</span>
        <p>{{ formInfoData.cuReturnNum }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>飞书审批编号：</span>
        <p>{{ formInfoData.approveNumber }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>详细说明：</span>
        <p>{{ formInfoData.remark }}</p>
      </div>
      <div class="form-desc form-desc1">
        <span>相关图片：</span>
        <div class="content">
          <el-image
            v-for="src in formInfoData.picture"
            :key="src"
            :src="src"
            fit="cover"
            :preview-src-list="formInfoData.picture"
          />
        </div>
      </div>
    </div>
    <div style="margin-top: 20px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>关联学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray || []" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" style="display: flex" @click="onGetStudentId(item)">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name || item.fullName }} ({{ item.code }})>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image } from 'element-ui';
import { openNewTab } from '../../../../apps/cp-class-advisor-center/src/utils/util';

import defaultImg from '../assets/service_pic_head.png';

export default {
  components: { ElImage: Image },
  props: {
    formInfoData: {
      type: Object,
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      form: this.$form.createForm(this),
      isRequired: false,
      CategoryEnum: {
        EXPECT: '学习期望',
        FEED_BACK: '教学反馈',
        OPERATION: '排课操作',
        FAULT: '系统故障',
      },
    };
  },
  methods: {
    goToReplay() {
      openNewTab(`/lessonReplay?courseScheduleId=${this.formInfoData.courseScheduleId}`);
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
  /deep/.content {
    flex: 1;
    display: flex;
    gap: 8px;
    .el-image {
      width: 72px;
      height: 72px;
    }
  }
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>

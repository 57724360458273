<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt" v-if="taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'">任务说明</span
              ><span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
            <div v-if="taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'">
              【完成方式】电话/文字沟通<br />
              【完成时效】主管选择交接时效<br />
              【操作步骤】<br />
              沟通前:<br />
              a.尽快处理好未解决的问题和需要注意的学生做成表格发送给上级及其承接学员班主任<br />
              b.把未处理完毕的审批尽快处理好，处理好后在交接群回复，如需新班主任协助则把相关审批转交给新班主任<br />
              c.请将【学生主页-服务页面】中的学员画像（学习风格、上课时间、班型课频、授课老师等）、家长画像（沟通时间及方式偏好等）补充完整<br />
              d.请将【学生主页-学情页面】中的学员当前学情（在上课程及进度、课堂参与度、出勤、作业等）补充完整<br />
              e.离职交接时若涉及账户中退款但流程未完成的学员，班主任仍需针对该学员进行流转<br />
              f.如遗留有待解决异议点，请补充完整至跟进记录<br />
              沟通中:<br />
              委婉告知家长将要离职，后续工作将由新班主任承接，并提醒家长及时通过新班主任的好友申请<br />
              沟通后：<br />
              请及时填写“原班主任交接学员”的跟进记录以完成本任务<br />
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}（ <span>{{ formInfoData.userRole }}</span> ）</span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>交接类型：</span>
          <p>{{ formInfoData.takeOverType }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>离职交接时间段：</span>
          <p>{{ formInfoData.takeOverTime }}</p>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" style="display: flex">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div v-if="taskType === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN'">
              <p @click="lookStudentInfo" class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name }} ({{ item.code }})
              </p>
              <p style="margin-top: 2px" @click="lookStudentInfo">查看学生资料</p>
            </div>
            <div v-else>
              <p
                @click="onGetStudentId(item)"
                class="student-name"
                :class="{ cursor: !formInfoData.showcourseSection }"
              >
                {{ item.name }} ({{ item.code }})>
              </p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    taskType: {
      type: String,
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
    };
  },
  methods: {
    lookWord() {
      if (this.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN') {
        window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh', '_blank');
      } else if (this.taskType === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
        window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh', '_blank');
      }
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    lookStudentInfo() {
      this.$emit('lookStudentInfo');
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>

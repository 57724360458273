<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }">
          {{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm') }}
          {{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}
        </span>
      </div>
      <div style="color: #333; font-weight: 600; margin-bottom: 12px">
        该学员剩余课时/额度，存在即将过期情况，请及时关注：
      </div>
      <div class="form-wrap">
        <span class="form-label">科目：</span>
        <span class="form-txt">{{ getDdlLabel(subjectArr, formInfoData.student.subject) }}</span>
      </div>
      <div class="form-wrap">
        <span class="form-label">即将过期课时：</span>
        <span class="form-txt">{{ formInfoData.totalCuNum }}</span>
      </div>
      <div class="form-wrap">
        <span class="form-label">即将过期额度：</span>
        <span class="form-txt">{{ formInfoData.totalQuotaNum }}</span>
      </div>
      <div class="form-wrap">
        <span class="form-label">明细：</span>
        <span class="form-txt"></span>
      </div>
      <a-table :columns="columns" size="small" :pagination="false" :data-source="formInfoData.classHourDetail">
        <template slot="expireTime" slot-scope="expireTime, record">
          {{ expireTime && $moment(expireTime).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </a-table>

      <div style="margin-top: 15px">
        <div class="title">
          <span>关联学生</span>
        </div>
        <div class="student-wrap">
          <div
            class="student-item"
            :class="{ cursor: !formInfoData.showcourseSection }"
            @click="onStudentId(formInfoData.student)"
          >
            <img :src="formInfoData.student.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ formInfoData.student.name }} ({{ formInfoData.student.code }})>
              </p>
              <p>学生</p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '课时来源',
          dataIndex: 'source',
          key: 'source',
        },
        {
          title: '当前有效期',
          dataIndex: 'expireTime',
          key: 'expireTime',
          width: 180,
          scopedSlots: { customRender: 'expireTime' },
        },

        {
          title: '当前剩余课时',
          dataIndex: 'cusInfo',
          key: 'cusInfo',
          // ellipsis: true,
          width: 300,
        },
      ],

      cursor: 'cursor',
      defaultImg,
      studyTaskForm: this.$form.createForm(this),
      isRequired: false,
    };
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getDdlLabel(ddlOptions, value) {
      if (!ddlOptions || ddlOptions.length < 1 || !value) {
        return '';
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ddlOptions.length; i++) {
        if (ddlOptions[i].value === value) {
          return ddlOptions[i].label;
        }
      }
    },
    // 打开关联任务的详情
    openConTaskDeatil(detailObj) {
      this.$emit('openConTaskDeatil', detailObj);
    },
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
  },
};
</script>

<style lang="less" scoped>
.form-wrap {
  margin-bottom: 10px;
}
.form-txt {
  color: #333;
  font-weight: 600;
}
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}
.reportPhase {
  display: flex;
}

.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
.takeoverTitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .student-name {
    color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
</style>

<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }">
          {{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm') }}
          {{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}
        </span>
      </div>
      <div class="study-desc">请确认以下订单的商品及寄送信息（点击查看详情或修改），确认无误后请点击【完成】：</div>
      <div class="study-wrap" @click="hrefOrder" v-if="formInfoData.trackOrder">
        <div class="study-top">
          <div>
            <span style="color: #009cff; margin-right: 10px">{{ formInfoData.trackOrder.orderNumber }}</span>
            <span>{{ formInfoData.trackOrder.orderType }}</span>
          </div>
          <div class="study-btnWrap" v-if="formInfoData.logisticsStatus === '待确认'">
            <button class="logBtn" @click.stop="addressHandle">
              {{ formInfoData.receiverProfile && formInfoData.logisticsStatus === '待确认' ? '确认地址' : '填写地址' }}
            </button>
          </div>
        </div>
        <div style="margin-top: 5px">学生：{{ formInfoData.studentName }}（{{ formInfoData.studentCode }}）</div>
        <div style="margin-top: 5px">
          <img class="consult-img" :src="defaultImg" alt="" />
          <span>{{ formInfoData.trackOrder.creatorName }}</span>
          <span>创建于</span>
          <span>{{ formInfoData.whenCreated }}</span>
        </div>
        <div style="margin-top: 5px">
          <span>订单状态：{{ formInfoData.orderStatus }}</span
          ><span style="margin-left: 50px">寄送状态：{{ formInfoData.logisticsStatus }}</span>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" @click="onStudentId(item)" style="display: flex">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name }} ({{ item.code }})>
              </p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <a-modal
      :title="formInfoData.receiverProfile ? '确认地址' : '填写地址'"
      :visible="addressVisible"
      @cancel="onClose"
    >
      <template slot="footer">
        <a-button key="back" @click="onClose" v-if="!formInfoData.receiverProfile || reFillIn"> 取消 </a-button>
        <a-button key="back" @click="handleCancel" v-if="formInfoData.receiverProfile && !reFillIn">
          重新填写
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" :disabled="loading" @click="onSubmit">
          确认并发货
        </a-button>
      </template>
      <a-form
        v-if="!formInfoData.receiverProfile || reFillIn"
        class="ant-address-search-form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 14 }"
        :form="form"
      >
        <a-form-item label="收货人">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入收货人!' }] }]" />
        </a-form-item>

        <a-form-item label="收货人电话">
          <a-input
            v-decorator="[
              'phoneNumber',
              {
                rules: [{ required: true, message: '请输入收货人电话!' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select
              slot="addonBefore"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-decorator="['prefix', { initialValue: '+86' }]"
              style="width: 70px"
            >
              <a-select-option :value="i" v-for="i of areaCodeArr" :key="i">
                {{ i }}
              </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
        <a-form-item label="国家/地区">
          <a-select
            show-search
            allowClear
            placeholder="国家/地区"
            option-filter-prop="children"
            @change="handleCountryChange"
            v-decorator="['address.country', { rules: [{ required: true, message: '请选择国家/地区!' }] }]"
          >
            <a-select-option :value="i.value" v-for="(i, index) of countryOptions" :key="index">
              {{ i.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="州/省">
          <a-select
            show-search
            allowClear
            placeholder="请选择"
            option-filter-prop="children"
            @change="handleProvinceChange"
            v-decorator="['address.province', { rules: [{ required: true, message: '请选择州/省!' }] }]"
            :options="provinceOptions"
          />
        </a-form-item>

        <a-form-item label="城市">
          <a-select
            show-search
            allowClear
            placeholder="请选择"
            option-filter-prop="children"
            :filter-option="cityFilterOption"
            v-decorator="['address.city', { rules: [{ required: true, message: '请选择城市!' }] }]"
            @search="handleCitySearch"
            @inputKeyDown="handleInput"
            @change="handleCityChange"
            :loading="cityLoading"
          >
            <a-select-option :value="i.key" v-for="(i, index) of cityOptions" :item="i">
              {{ i.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="街道">
          <a-input v-decorator="['address.street', { rules: [{ required: true, message: '请输入街道!' }] }]" />
        </a-form-item>

        <a-form-item label="邮政编码">
          <a-input v-decorator="['postCode', { rules: [{ required: true, message: '请输入邮政编码!' }] }]" />
        </a-form-item>
      </a-form>
      <div v-else>
        <div class="receiverMain">
          <p><span>收货人</span>：{{ formInfoData.receiverProfile && formInfoData.receiverProfile.name }}</p>
          <p><span>收货人电话</span>：{{ formInfoData.receiverProfile && formInfoData.receiverProfile.phoneNumber }}</p>
          <p><span>国家/地区</span>：{{ countryLabel }}</p>
          <p><span>州/省</span>：{{ stateLabel }}</p>
          <p><span>城市</span>：{{ cityLabel }}</p>
          <p>
            <span>具体地址</span>：{{
              formInfoData.receiverProfile &&
              formInfoData.receiverProfile.address &&
              formInfoData.receiverProfile.address.street
            }}
          </p>
          <p><span>邮政编号</span>：{{ formInfoData.receiverProfile && formInfoData.receiverProfile.postCode }}</p>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import axios from 'axios';
import {
  getLabelFromOptions,
  getProvinceOptions,
  getCityOptions,
  getCountryOptions,
  isUnknownOption,
} from 'cp-class-advisor-center/src/utils/geoLocation';
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';
import debounce from 'lodash.debounce';

const session = storage.createStore(sessionStorage);

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      countryOptions: [],
      provinceOptions: [],
      timezoneOptions: [],
      cityOptions: [],
      isFillIn: false, // 入学档案是否有地址 未完成需后端
      operateType: '', // 区分是点击的下载还是发送至群
      cursor: 'cursor',
      defaultImg,
      reportData: {},
      showFlag: false,
      visibleFlag: false, // 用来重新触发下载功能
      sectionType: '', // 阶段几
      addressVisible: false,
      loading: false,
      form: this.$form.createForm(this),
      countries: null,
      reFillIn: false,
      cityLoading: false,
    };
  },
  watch: {
    formInfoData: {
      immediate: true,
      handler(v) {
        const country = v?.receiverProfile?.address?.country;
        const state = v?.receiverProfile?.address?.province;

        if (country) {
          getProvinceOptions(country).then((res) => (this.provinceOptions = res));
        }
        if (country && state) {
          getCityOptions({ countryId: country, provinceId: state }).then((res) => (this.cityOptions = res));
        }
      },
    },
  },
  computed: {
    isFillInBol() {
      return this.formInfoData.receiverProfile;
    },
    countryLabel() {
      return getLabelFromOptions(this.countryOptions, this.formInfoData.receiverProfile.address.country);
    },
    stateLabel() {
      return getLabelFromOptions(this.provinceOptions, this.formInfoData.receiverProfile.address.province);
    },
    cityLabel() {
      return getLabelFromOptions(this.cityOptions, this.formInfoData.receiverProfile.address.city);
    },
  },
  mounted() {
    this.initData();
    getCountryOptions().then((data) => (this.countryOptions = data));
  },
  methods: {
    // async handleCountryChange(v) {
    //   getProvinceOptions(v).then((res) => (this.provinceOptions = res));
    //   this.form.setFieldsValue({
    //     province: undefined,
    //     city: undefined,
    //   });
    // },
    async handleCountryChange(v) {
      getProvinceOptions(v).then((res) => (this.provinceOptions = res));
      this.cityOptions = [];
      this.form.setFieldsValue({
        nationalTimeZone: undefined,
        address: {
          country: v,
          province: undefined,
          city: undefined,
        },
      });
    },
    // async handleProvinceChange(v) {
    //   getCityOptions({ countryId: this.form.getFieldValue('address.country'), provinceId: v }).then(
    //     (res) => (this.cityOptions = res),
    //   );
    //   this.form.setFieldsValue({
    //     city: undefined,
    //   });
    // },
    async handleProvinceChange(v) {
      getCityOptions({
        countryId: this.form.getFieldValue('address.country'),
        provinceId: v,
        showDoubleLanguage: true,
      }).then((res) => (this.cityOptions = res));
      this.form.setFieldsValue({
        address: {
          ...this.form.getFieldValue('address'),
          city: undefined,
        },
      });
    },
    handleInput() {
      this.cityOptions = [];
    },
    handleCitySearch: debounce(function (v) {
      this.cityLoading = true;
      getCityOptions({
        countryId: this.form.getFieldValue('address.country'),
        provinceId: this.form.getFieldValue('address.province'),
        city: v,
        showDoubleLanguage: true,
      })
        .then((res) => {
          this.cityOptions = res;
          this.cityLoading = false;
        })
        .catch(() => {
          this.cityLoading = false;
        });
    }, 500),
    unique(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i].areaCode);
        }
      }
      return arr1;
    },
    initData() {
      axios.get(`${process.env.VUE_APP_API_IM_URL}/public/ref/countries/zh/ddl`).then((i) => {
        this.countries = i.data.content;
        this.areaCodeArr = this.unique(i.data.content);
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let datas = values;
          datas.phoneNumber = datas.prefix ? `${datas.prefix} ${datas.phoneNumber}` : `${datas.phoneNumber}`;
          delete datas.prefix;

          const { orderId } = this.formInfoData;
          this.loading = true;

          if (this.formInfoData.receiverProfile && !this.reFillIn) {
            datas = this.formInfoData.receiverProfile;
          }
          // 城市处理

          if (datas?.address?.city) {
            const sendCityKeys = datas.address.city?.split('-') ?? [];
            datas.address.city = sendCityKeys?.[sendCityKeys?.length - 1];
          }
          axios
            .post(`${process.env.VUE_APP_API_IM_URL}/api/student/order/${orderId}/logistics`, {
              studentId: this.formInfoData.studentId,
              studentCode: this.formInfoData.studentCode,
              logisticReq: datas,
            })
            .then((i) => {
              this.loading = false;
              this.$message.success('提交成功');
              this.onClose();
              this.$emit('handleConfirmAddress');
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    cityFilterOption(value, o) {
      const input = value ? value?.trim() : value;
      if (!input) return true;
      return o?.data?.attrs?.item?.label.toLowerCase().includes(input.toLowerCase());
    },
    handleCityChange(v, o) {
      const country = this.form.getFieldValue('address.country');
      const state = this.form.getFieldValue('address.province');
      // 如果没有选中国家或者省份，则通过城市反填数据
      if (!country || !state) {
        const option = o?.data?.attrs?.item;
        if (!option || isUnknownOption(option)) return;
        this.handleCountryChange(option.countryId);
        // 这里先给表单赋值，因为 handlProvinceChange 是从表单中取的国家
        // 最好通过参数传递
        const address = this.form.getFieldValue('address');
        this.form.setFieldsValue({ address: { ...address, country: option.countryId, province: option.provinceId } });
        this.handleProvinceChange(option.provinceId);
        this.form.setFieldsValue({ address: { ...address, country: option.countryId, province: option.provinceId } });
      }
    },
    // 填写地址
    addressHandle() {
      this.addressVisible = !this.addressVisible;
      if (this.formInfoData.receiverProfile) {
        const data = this.formInfoData.receiverProfile;
        this.$nextTick(() => {
          this.form.setFieldsValue({ name: data?.name });
          this.form.setFieldsValue({ phoneNumber: data?.phoneNumber?.split(' ')?.[1] });
          this.form.setFieldsValue({ prefix: data?.phoneNumber?.split(' ')?.[0] });
          this.form.setFieldsValue({ postCode: data?.postCode });
          this.form.setFieldsValue({ 'address.country': data?.address?.country });
          this.form.setFieldsValue({ 'address.province': data?.address?.province });

          if (data?.address?.city && data?.address?.coutnry && data?.address?.state) {
            this.form.setFieldsValue({
              'address.city': `${data?.address?.coutnry}-${data?.address?.state}-${data?.address?.city}`,
            });
          }

          this.form.setFieldsValue({ 'address.street': data?.address?.street });
        });
      }
    },
    onClose() {
      this.form.resetFields();
      this.reFillIn = false;
      this.addressVisible = false;
    },
    handleCancel() {
      this.form.resetFields();

      this.reFillIn = true;
      if (this.formInfoData.receiverProfile) {
        const data = this.formInfoData.receiverProfile;
        this.$nextTick(() => {
          this.form.setFieldsValue({ name: data?.name });
          this.form.setFieldsValue({ phoneNumber: data?.phoneNumber?.split(' ')?.[1] });
          this.form.setFieldsValue({ prefix: data?.phoneNumber?.split(' ')?.[0] });
          this.form.setFieldsValue({ postCode: data?.postCode });
          this.form.setFieldsValue({ 'address.country': data?.address?.country });
          this.form.setFieldsValue({ 'address.province': data?.address?.province });
          this.form.setFieldsValue({ 'address.city': data?.address?.city });
          this.form.setFieldsValue({ 'address.street': data?.address?.street });
        });
      }
    },
    // 跳转订单
    hrefOrder() {
      const student = {
        fullName: this.formInfoData.studentName,
        code: this.formInfoData.studentCode,
      };
      // 记录选中学生信息
      session.set('studentProfile', student);
      const routeUrl = this.$router.resolve({
        path: '/orderDetail',
        query: { orderId: this.formInfoData.trackOrder.orderId },
      });
      window.open(routeUrl.href, '_blank');
    },

    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
  },
};
</script>
<style lang="less">
.receiverMain {
  padding: 20px;
  background-color: #f7f7f7;
  margin-top: 20px;
  & > p {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #000;
    span {
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
    }
  }
}
.ant-address-search-form .ant-row .ant-col label {
  font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
  position: relative;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}

.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.logBtn {
  color: #00cf64;
  border: 1px solid #00cf64;
  background-color: #fff;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  p {
    margin-left: 8px;
  }
}
.reportPhase {
  display: flex;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.icon-starlight {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star_ed.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
.icon-star {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
/deep/ .ant-form label {
  font-size: 16px !important;
}
.study-wrap {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 14px;
  color: #333;
  margin: 15px 0;
  border-radius: 5px;
  background-color: #fafafa;
  cursor: pointer;
}
.study-txt {
  font-size: 14px;
  margin-top: 5px;
}
.study-top {
  display: flex;
  justify-content: space-between;
}
.study-btnWrap {
  font-size: 14px;
  color: #009cff;
}

.download-poster {
  margin-right: 15px;
  cursor: pointer;
}
.send-group {
  cursor: pointer;
}
.consult-img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
</style>

<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip
          :overlayStyle="{ maxWidth: '500px' }"
          v-if="
            formInfoData.subDetailType === 'classAdmin' &&
            ['APPLICATION_FOR_TEACHER_LEAVE', 'APPLICATION_FOR_TEACHER_EN', 'TEACHER_LEAVE_FOLLOW'].includes(detailType)
          "
        >
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
          </template>
          <i
            class="explainTC"
            v-if="
              ['APPLICATION_FOR_TEACHER_LEAVE', 'APPLICATION_FOR_TEACHER_EN', 'TEACHER_LEAVE_FOLLOW'].includes(
                detailType,
              )
            "
          ></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName }}
          <span v-if="formInfoData.startUserName === '系统'">自动</span>
          <span v-else>（ {{ formInfoData.userRole }} ） </span>
        </span>
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>

      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span class="form-label">发起人</span>
      </div>
      <div class="form-desc form-desc2">
        {{ formInfoData.teacherName || formInfoData.startUserName }}
        <span v-if="formInfoData.userRole">（{{ formInfoData.userRole }}）</span>
      </div>
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span class="form-label">请假时间（北京时间）</span>
      </div>
      <div class="form-desc form-desc2">
        {{ formInfoData.leaveStartTime.substring(0, 16) }} 至 {{ formInfoData.leaveEndTime.substring(0, 16) }}
      </div>
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span>请假原因</span>
      </div>
      <div class="form-desc form-desc2">
        {{ formInfoData.description }}
      </div>
      <div class="form-desc form-desc1">
        <span class="red-icon">*</span>
        <span>原因类型</span>
      </div>
      <div class="form-desc form-desc2">
        <span class="reasonType" v-for="i in formInfoData.reasonType && formInfoData.reasonType.split(',')" :key="i">{{
          i
        }}</span>
        <span v-if="!formInfoData.reasonType">无</span>
      </div>
      <div class="form-desc form-desc1" v-if="formInfoData.reason">
        <span class="red-icon">*</span>
        <span>具体请假原因</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.reason">
        {{ formInfoData.reason }}
      </div>
      <div class="form-desc form-desc1" v-if="formInfoData.turnManualReason">
        <span class="red-icon">*</span>
        <span>转人工原因</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.turnManualReason">
        {{ turnManualReasonTextMap[formInfoData.turnManualReason] }}
      </div>
      <!-- <template v-if="todoTextMap[formInfoData.turnManualReason]">
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>待办提示</span>
        </div>
        <div class="form-desc form-desc2">
          <div>
            <p v-for="item in todoTextMap[formInfoData.turnManualReason]">{{ item }}</p>
          </div>
        </div>
      </template> -->
      <div class="form-desc form-desc1" v-if="formInfoData.handleCourseList && formInfoData.handleCourseList.length">
        <span class="red-icon">*</span>
        <span>系统处理结果</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.handleCourseList && formInfoData.handleCourseList.length">
        <a-table
          :columns="columns"
          size="small"
          :pagination="false"
          :data-source="formInfoData.handleCourseList"
          style="width: 100%"
        >
          <div slot="scheduleStatus" slot-scope="text, record">
            <span>{{ scheduleStatusTextMap[record.scheduleStatus] }}</span>
          </div>
          <div slot="dealType" slot-scope="text, record">
            <span>{{ dealTypeTextMap[record.dealType] }}</span>
          </div>
          <div slot="result" slot-scope="text, record">
            <span>{{ resultTextMap[record.result] }}</span>
          </div>
        </a-table>
      </div>

      <div class="form-desc form-desc1" v-if="formInfoData.scheduleSize">
        <span class="red-icon">*</span>
        <span>请假总课节</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.scheduleSize">
        {{ formInfoData.scheduleSize || '无' }}
      </div>
      <div class="form-desc form-desc1" v-if="formInfoData.duration">
        <span class="red-icon">*</span>
        <span>代课总时长</span>
      </div>
      <div class="form-desc form-desc2" v-if="formInfoData.duration">
        {{ formInfoData.duration / 60 + '小时' || '无' }}
      </div>
      <div class="form-imgs" v-if="formInfoData.imgs">
        <img v-for="(item, index) in formInfoData.imgs.split(',')" :src="item" preview="1" :key="index" alt="" />
      </div>
      <div style="margin-top: 20px" v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
        <div class="title">
          <span>关联学生</span>
        </div>
        <div class="student-wrap">
          <div v-for="item in formInfoData.studentArray || []" :key="item.id" class="student-item">
            <div :class="{ cursor: !formInfoData.showcourseSection }" style="display: flex" @click="onStudentId(item)">
              <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
              <div>
                <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                  {{ item.name || item.fullName }} ({{ item.code }})>
                </p>
              </div>
            </div>
            <div style="display: flex">
              <AssociatedTasks
                type="other"
                :totalNum="formInfoData.currentStudentOtherTaskNumber"
                :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
                style="margin-right: 10px"
              />
              <AssociatedTasks
                type="familyOther"
                :totalNum="formInfoData.otherStudentTaskNumber"
                :taskList="formInfoData.otherStudentTasks || []"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="concat-wrap" v-if="formInfoData.relatedClass">
        <div class="title">关联班级</div>
        <RelatedClass
          :processInstanceId="formInfoData.processInstanceId"
          :adjustScheduleProcess="formInfoData.adjustScheduleProcess"
          :upComingTypeValues="formInfoData.upComingTypeValues"
          :taskId="formInfoData.taskId"
          class="concat-con"
          v-for="(classItem, index) in formInfoData.relatedClass"
          :classItem="classItem"
          :key="index"
          :showOperate="detailType !== 'TEACHER_LEAVE_FOLLOW' && !formInfoData.turnManualReason"
          :subject="formInfoData.subject"
          :startDateTimeFrom="$moment(formInfoData.leaveStartTime).format('YYYY-MM-DD HH:mm:ss')"
          :startDateTimeTo="$moment(formInfoData.leaveEndTime).format('YYYY-MM-DD HH:mm:ss')"
          :teacherId="formInfoData.teacherId"
          :substitute="true"
          @onGetStudentId="onGetStudentId"
          @courseScheduleInfo="courseScheduleInfo"
        >
        </RelatedClass>
      </div>
    </div>
    <ApplicantComponent
      :subDetailType="subDetailType"
      :applicant="{ name: formInfoData.applyUser, reles: formInfoData.applyUserRoles }"
    />
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedClass from './RelatedClass.vue';
import ApplicantComponent from './ApplicantComponent.vue';

export default {
  components: { ApplicantComponent, RelatedClass },
  watch: {
    formInfoData: {
      handler(v) {
        console.log('formInfoDataformInfoDataformInfoData', v);
      },
      immediate: true,
    },
  },
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      turnManualReasonTextMap: {
        COURSE_SCHEDULE_CHANGE: '”改约“导致取消排课单',
        COURSE_SCHEDULE_CANCEL: '人工取消课节导致系统的排课单被取消',
        SCHEDULE_ADJUST: '人工调整课节进度后导致系统的排课单被取消',
        TIME_ADJUST: '人工调整上课时间后导致系统的排课单被取消',
        TEACHER_ADJUST: '人工更改授课老师后导致系统的排课单被取消',
        STUDENT_LEAVE: '家长请假后导致系统的排课单被取消',
        SCHEDULE_ADJUST_FAIL: '课节更换老师或课节被取消，导致课节进度调整失败',
        SCHEDULE_ORDER_EXISTS: '老师请假时班级内有发起的排课单',
        DIRECTLY_CANCEL: '直接取消',
      },
      todoTextMap: {
        COURSE_SCHEDULE_CHANGE: undefined,
        COURSE_SCHEDULE_CANCEL: [
          '1. 课节状态=已取消。辅导老师知晓即可。',
          '2. 课节状态=待开课。需要辅导老师人工处理，因授课老师请假，所以需要安排代课老师或取消课节',
        ],
        SCHEDULE_ADJUST: [
          '1. 课节状态=已取消。需要辅导老师核对并调整班级待开课课节进度',
          '2. 课节状态=待开课。需要辅导老师人工处理，因授课老师请假，所以需要安排代课老师或取消课节，并核对班级课节进度',
        ],
        TIME_ADJUST: [
          '1. 课节状态=已取消。辅导老师知晓即可',
          '2. 课节状态=待开课。需要辅导老师人工处理，若存在与老师请假时间重合的课节，需要安排代课老师或取消课节',
        ],
        TEACHER_ADJUST: [
          '1. 课节状态=已取消。辅导老师知晓即可',
          '2. 课节状态=待开课。需要辅导老师人工处理，确认原授课老师安排',
        ],
        STUDENT_LEAVE: [
          '1. 课节状态=已取消。辅导老师知晓即可',
          '2. 课节状态=待开课。需要辅导老师人工处理，因授课老师请假，所以需要安排代课老师或取消课节',
        ],
        SCHEDULE_ADJUST_FAIL: [
          '1. 课节状态=已取消。需要辅导老师核对并调整班级待开课课节进度',
          '2. 课节状态=待开课。需要辅导老师人工处理，因授课老师请假，所以需要安排代课老师或取消课节，并核对班级课节进度',
        ],
        SCHEDULE_ORDER_EXISTS: [
          '1. 课节状态=已取消。辅导老师知晓即可',
          '2. 课节状态=待开课。需要辅导老师人工处理，因授课老师请假，所以需要安排代课老师或取消课节',
        ],
        DIRECTLY_CANCEL: undefined,
      },
      dealTypeTextMap: {
        NO_NEED_DEAL: '无需处理',
        SUBSTITUTE_OR_CANCEL: '找代课或者取消',
        CHECK_SECTION_LEVEL: '核对课节进度',
      },
      scheduleStatusTextMap: {
        PRE_SCHEDULING: '预排课',
        NORMAL: '待开课',
        COMPLETED: '已完成',
        CANCELLED: '已取消',
      },
      resultTextMap: {
        CHANGE_TEACHER: '匹配代课老师',
        CREATE_SCHEDULE_ORDER: '创建排课单',
        CANCEL_SCHEDULE: '取消',
        SCHEDULE_INFO_CHANGE: '课节信息变更',
        ABNORMAL: '处理失败',
        ADJUST_SECTION_FAIL: '进度调整失败',
        CANCEL_ORDER_THEN_SCHEDULE_CANCEL: '取消课节导致取消系统排课单',
        ADJUST_SECTION_THEN_SCHEDULE_CANCEL: '调进度导致取消系统排课单',
        ADJUST_TIME_THEN_SCHEDULE_CANCEL: '调时间导致取消系统排课单',
        CHANGE_TEACHER_THEN_SCHEDULE_CANCEL: '更改授课老师导致取消系统排课单',
        STUDENT_LEAVE_THEN_SCHEDULE_CANCEL: '家长请假导致取消系统排课单',
        SCHEDULE_ORDER_EXISTS: '班级内有人工发起的排课单',
      },
      columns: [
        {
          title: '课节名称',
          dataIndex: 'courseScheduleName',
        },

        {
          title: '开课时间',
          dataIndex: 'currentStartDateTime',
        },

        {
          title: '授课老师',
          dataIndex: 'currentTeacherName',
        },
        {
          title: '课节状态',
          dataIndex: 'scheduleStatus',
          scopedSlots: { customRender: 'scheduleStatus' },
        },
        {
          title: '是否需人工处理',
          dataIndex: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '系统处理结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' },
        },
      ],
    };
  },

  methods: {
    courseScheduleInfo() {
      this.$emit('courseScheduleInfo');
    },
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcnPdnJEeTX66IonyqwKSgaoe?sheet=h2ZIz4', '_blank');
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    // 切换收起详情
    toggleClass() {
      this.visibleClass = !this.visibleClass;
      this.toggleTxt = this.visibleClass ? '收起' : '详情';
    },
    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
      this.$emit('onPictures', datas);
    },
    onCloseImg() {
      this.isCarousel = false;
    },
  },
};
</script>

<style lang="less" scoped>
.reasonType {
  padding: 2px 10px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-right: 10px;
}
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
  display: flex;
}
.form-desc1 {
  margin-bottom: 6px;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}

.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>

<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          :class="{
            dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() && !formInfoData.finished,
          }"
        >
          {{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm') }}
          {{
            new Date().getTime() > new Date(formInfoData.dueDate).getTime() && !formInfoData.finished ? '(逾期)' : ''
          }}
        </span>
      </div>
      <div>
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>学生首课时间:</span>
          <p>{{ formInfoData.firstCourseDate || '无 ' }}</p>
        </div>
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>审批原因:</span>
          <p>{{ formInfoData.reason || '无 ' }}</p>
        </div>
        <!-- <div class="form-desc1">
          <span class="red-icon"></span>
          <span>补充说明:</span>
          <p>{{ formInfoData.description || '无 ' }}</p>
        </div> -->
        <div class="form-desc1 form-imgs" v-if="formInfoData.imgs">
          <!-- <span>相关图片:</span> -->
          <div>
            <img v-for="item in formInfoData.imgs.split(',')" :src="item" preview="1" :key="item" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div
          v-for="item in formInfoData.studentArray"
          :key="item.id"
          class="student-item"
          :class="{ cursor: !formInfoData.showcourseSection }"
          @click="onStudentId(item)"
        >
          <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
          <div>
            <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
              {{ item.name }} ({{ item.code }})>
            </p>
          
          </div>
        </div>
      </div>
    </div>
    <div class="concat-wrap" v-if="formInfoData.relatedTask">
      <div class="title">关联任务</div>
      <div class="concat-con">
        <span>任务名称：{{ formInfoData.relatedTask.name }}</span>
        <a-button @click="onMakeUp" :disabled="!formInfoData.finished || formInfoData.deleted" type="primary"
          >去补升</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
     subDetailType:{//区分是老师还是班主任，班主任主题色是绿色
      type:String,
      default:'teacher'
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      studyTaskForm: this.$form.createForm(this),
      isRequired: false,
    };
  },
  methods: {
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.id });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    onMakeUp() {
      this.$emit('onMakeUp');
    },
    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
      this.$emit('onPictures', datas);
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43D186 !important;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  cursor: pointer;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  p {
    margin-left: 8px;
  }
}
.reportPhase {
  display: flex;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.icon-starlight {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star_ed.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
.icon-star {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
/deep/ .ant-form label {
  font-size: 16px !important;
}
.classAdmin{
  .title::before{
    background-color:#43D186;
  }
  .course-name{
    color:#43D186;
  }
}
</style>

<template>
  <div class="report-main">
    <template v-if="JSON.stringify(dataInfo) !== '{}'">
      <div class="report-header">
        <div class="subtract-title">
          <div class="subtract">
            <img src="../assets/Subtract.png" alt="" />
          </div>
          <div>
            <p class="report-name">
              {{ moment(new Date(dataInfo.whenCreated)).format('YYYY年MM月DD日') }}{{ dataInfo.linkName }}报告
            </p>
            <div class="answer-time">
              <div>
                <a-icon type="clock-circle" />答题总时长：<span>{{
                  classOutInTime(dataInfo.totalAnswerTime) || '暂无数据'
                }}</span>
              </div>
              <div>
                <a-icon type="up-circle" />提交时间：
                <span>{{ dataInfo.whenCreated || '暂无数据' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="fraction-main">
          <p v-if="isAverageScore">
            朗读题平均得分：<span class="fraction">{{ dataInfo.averageScore || 0 }}</span>
          </p>
          <p v-if="isErrorNumber && dataInfo.linkAnswerType !== 'QUIZ_MODE' && dataInfo.linkAnswerType !== 'QUESTS'">
            互动题错误次数： <span class="fraction">{{ dataInfo.totalErrorNumber || 0 }}</span>
          </p>
          <p v-if="dataInfo.whetherScore">
            <!-- <p v-if="dataInfo.exerciseType === 'SERIOUS_EXERCISE'"> -->
            总分： <span class="fraction">{{ dataInfo.reportData.score || 0 }}</span>
          </p>
        </div>
        <div class="answers-overview">
          <p class="all-answers">答题总览：</p>
          <div class="answers">
            <span class="correct">{{ getAllAnswer.correctNumber }}</span>
            正确
          </div>
          <div class="answers">
            <span class="error">{{ getAllAnswer.errorNumber }}</span>
            错误
          </div>
          <div class="answers">
            <span class="skip">{{ getAllAnswer.skipNumber }}</span>
            未做
          </div>
          <div class="answers">
            <span>{{ data.length }}</span>
            题目总数
          </div>
        </div>
      </div>
      <div class="report-expand">
        <p class="expand-btn" @click="onExpand">
          {{ is_expand ? '展开详情' : '收起详情' }}
          <a-icon :type="is_expand ? 'down' : 'up'" />
        </p>
      </div>
      <div v-if="!is_expand" class="report-list">
        <div class="topics-list" v-for="(i, i_index) in Math.ceil(data.length / 10)" :key="i_index">
          <div class="topics-item topics-title">
            <div>题号</div>
            <div><p>题型</p></div>
            <div>答题情况</div>
          </div>
          <div class="topics-item" v-for="(item, index) in data.slice(10 * i_index, 10 * (i_index + 1))" :key="index">
            <div>{{ 10 * i_index + index + 1 }}</div>
            <div class="topics-main">{{ questionType(item.questionType) }}</div>
            <div
              v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'"
              :style="{
                background:
                  item.answerStatus === 'ERROR' ? '#fdeeee' : item.answerStatus === 'SKIP' ? '#F2F2F2' : '#f1faf5',
              }"
            >
              <img v-if="item.answerStatus === 'CORRECT'" src="../assets/Frame 36.png" alt="" />
              <img v-else-if="item.answerStatus === 'ERROR'" src="../assets/Frame 45.png" alt="" />
              <img v-else-if="item.answerStatus === 'SKIP'" src="../assets/FrameNo.png" alt="" />
            </div>
            <div
              v-else
              :style="{
                background: countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)
                  ? '#f1faf5'
                  : '#fdeeee',
              }"
            >
              <img
                v-if="countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)"
                src="../assets/Frame 36.png"
                alt=""
              />
              <img v-else src="../assets/Frame 45.png" alt="" />
            </div>
          </div>
        </div>

        <div v-for="(item, index) in data" :key="item.id">
          <div
            class="report-item"
            v-if="
              item.questionType === 'PICTURE_BOOK' ||
              item.questionType === 'READING_QUESTION' ||
              item.questionType === 'READ_BOOK'
            "
          >
            <div class="report-unit">
              <template v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'">
                <img v-if="item.answerStatus === 'CORRECT'" src="../assets/Frame 36.png" alt="" />
                <img v-else-if="item.answerStatus === 'ERROR'" src="../assets/Frame 45.png" alt="" />
                <img v-else-if="item.answerStatus === 'SKIP'" src="../assets/FrameNo.png" alt="" />
              </template>
              <template v-else>
                <img
                  v-if="countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)"
                  src="../assets/Frame 36.png"
                  alt=""
                />
                <img v-else src="../assets/Frame 45.png" alt="" />
              </template>
              <div>
                <div
                  class="item-title"
                  v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'"
                  :class="{
                    'title-error': item.answerStatus === 'ERROR',
                    'title-skip': item.answerStatus === 'SKIP',
                  }"
                >
                  <p>{{ index + 1 }} 、{{ item.title }}</p>
                  <span v-if="item.tag">{{ item.tag }}</span>
                </div>
                <div
                  v-else
                  class="item-title"
                  :class="{
                    'title-error': !countanswerStatus(item.questionType, item.answerStatus, item.errorNumber),
                  }"
                >
                  <p>{{ index + 1 }} 、{{ item.title }}</p>
                  <span v-if="item.tag">{{ item.tag }}</span>
                </div>
                <div class="item-audio">
                  <span>朗读录音: </span>
                  <audio v-if="item.readRecording" :src="item.readRecording" controls="controls" preload></audio>
                  <span v-else>无</span>
                </div>
                <div class="item-audio">
                  <span
                    >朗读得分:
                    <span v-if="typeof item.readScore === 'number'"> {{ item.readScore || 0 }}分 </span>
                    <span v-else>无</span>
                  </span>
                </div>
                <div class="item-audio" v-if="item.questionType === 'READ_BOOK'">
                  <span
                    >朗读星级:
                    <a-rate
                      v-if="typeof item.readScore === 'number'"
                      :value="getRateValue(Number(item.readScore || 0))"
                      :count="3"
                      disabled
                    />
                    <span v-else>无</span>
                  </span>
                </div>
                <div class="item-ai" v-if="aiAnalysisHandle(item.aiAnalysis)">
                  <span>AI分析 </span
                  >{{
                    aiAnalysisHandle(item.aiAnalysis)
                      ? aiAnalysisHandle(item.aiAnalysis)
                      : item.answerStatus === 'SKIP'
                      ? '无'
                      : '正确'
                  }}
                </div>
                <a-button class="show-info" @click="onShowInfo(item.questionId, item)">查看详情</a-button>
                <div class="showInfo" v-if="showInfo === item.questionId">
                  <div class="showInfo-main">
                    <div class="title">
                      <p>答题详情</p>
                      <a-icon type="close" @click="onClose" />
                    </div>
                    <div class="backfill">
                      <p>学生回答：</p>
                      <div class="backfill-main">
                        <Exercise :segments="errorInfoData" segmentId="Segment1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="report-item" v-else>
            <div class="report-unit">
              <template v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'">
                <img v-if="item.answerStatus === 'CORRECT'" src="../assets/Frame 36.png" alt="" />
                <img v-else-if="item.answerStatus === 'ERROR'" src="../assets/Frame 45.png" alt="" />
                <img v-else-if="item.answerStatus === 'SKIP'" src="../assets/FrameNo.png" alt="" />
              </template>
              <template v-else>
                <img
                  v-if="!countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)"
                  src="../assets/Frame 45.png"
                  alt=""
                />
                <img v-else src="../assets/Frame 36.png" alt="" />
              </template>
              <div>
                <div
                  v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'"
                  class="item-title"
                  :class="{
                    'title-error': item.answerStatus === 'ERROR' || (item.errorNumber && !item.answerStatus),
                    'title-skip': item.answerStatus === 'SKIP',
                  }"
                >
                  <p v-if="item.questionType !== 'EXPLAN_CHOICE' && item.questionType !== 'SINGLE_CHOICE'">
                    {{ index + 1 }} 、{{ item.title }}
                  </p>
                  <div v-else style="display: flex; justify-content: flex-start">
                    <p>{{ index + 1 }}、</p>
                    <div>
                      <QuillRender :delta="transformStringToDelta(item.title)" style="color: #333 !important" />
                    </div>
                  </div>
                  <span v-if="item.tag">{{ item.tag }}</span>
                </div>
                <div
                  v-else
                  class="item-title"
                  :class="{
                    'title-error': !countanswerStatus(item.questionType, item.answerStatus, item.errorNumber),
                  }"
                >
                  <p v-if="item.questionType !== 'EXPLAN_CHOICE' && item.questionType !== 'SINGLE_CHOICE'">
                    {{ index + 1 }} 、{{ item.title }}
                  </p>
                  <div v-else style="display: flex; justify-content: flex-start">
                    <p>{{ index + 1 }}、</p>
                    <div>
                      <QuillRender :delta="transformStringToDelta(item.title)" style="color: #333 !important" />
                    </div>
                  </div>
                  <span v-if="item.tag">{{ item.tag }}</span>
                </div>
                <div class="item-audio item-error" v-if="dataInfo.linkAnswerType === 'QUESTS' && item.errorNumber">
                  <span>错误次数：{{ item.errorNumber }} </span>
                </div>
                <div class="item-audio item-time">
                  <span>{{ item.uuid }}答题时长：{{ classOutInTime(item.answerTime) || '0s' }}</span>
                </div>
                <div v-if="item.questionType === 'EXPLAN_CHOICE'">
                  <div class="explain-title" @click="explainResolution(item.questionId)">
                    <p>题目解析</p>
                    <a-icon
                      :type="JSON.stringify(explainData) !== '{}' && questionId === item.questionId ? 'up' : 'down'"
                    />
                  </div>
                  <div class="explain-main" v-if="explainData && questionId === item.questionId">
                    <div v-for="(item, index) in explainData.explan" :key="index">
                      <p v-if="typeof item.insert === 'string' && item.insert.replace(/\r\n/g, '')">
                        {{ item.insert.replace(/\r\n/g, '') }}
                      </p>
                      <img v-if="item.insert.image" :src="item.insert.image" style="width: 100%" alt="" />
                      <p v-if="item.insert.formula">{{ item.insert.formula }}</p>
                    </div>
                    <video
                      v-if="explainData.explanVideo"
                      class="explain-video"
                      :src="explainData.explanVideo"
                      controls="controls"
                    ></video>
                    <div class="item-audio" v-if="explainData.explanAudio">
                      <audio :src="explainData.explanAudio" controls="controls" preload></audio>
                    </div>
                  </div>
                </div>
                <a-button class="show-info" @click="onShowInfo(item.questionId, item)">查看详情</a-button>
                <div class="showInfo" v-if="showInfo === item.questionId">
                  <div class="showInfo-main">
                    <div class="title">
                      <p>答题详情</p>
                      <a-icon type="close" @click="onClose" />
                    </div>
                    <div
                      class="backfill"
                      v-if="
                        dataInfo.linkAnswerType === 'QUIZ_MODE' &&
                        item.questionType !== 'WRITE_QUESTION' &&
                        item.questionType !== 'AUTO_WRITE' &&
                        item.answerStatus !== 'SKIP'
                      "
                    >
                      <p>学生回答：</p>
                      <div class="backfill-main">
                        <Exercise :segments="errorInfoData" segmentId="Segment1" />
                      </div>
                    </div>
                    <div class="backfill correct">
                      <p>正确答案：</p>
                      <div class="backfill-main">
                        <Exercise :segments="correctInfoData" segmentId="Segment1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="empty">
      <p>报告还未提交</p>
      <img src="../assets/pic_emptypage.svg" alt="" />
    </div>
  </div>
</template>
<script>
import { Exercise, withIframe } from '@wk/iexercise';
import moment from 'moment';
import QuillRender from '@wk/iexercise/lib/components/QuillRender';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';

export default {
  components: { QuillRender, Exercise: withIframe(Exercise) },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    parentaudio: {
      type: String,
      default: '',
    },
    checkData: {
      type: Array,
      default: () => [],
    },
    explainData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
      questionId: '',
      // audio: null,
      isAudio: false,
      data: this.dataInfo?.reportData?.answerRecord || [],
      linkId: this.dataInfo?.linkId,
      is_expand: true,
      showInfo: '',
      correctInfoData: null,
      errorInfoData: null,
      explanShow: false,
      showError: true,
    };
  },
  computed: {
    isAverageScore() {
      return this.data.some((item) => item.questionType === 'PICTURE_BOOK');
    },

    isErrorNumber() {
      return this.data.some((item) => item.questionType !== 'PICTURE_BOOK');
    },
    getAllAnswer() {
      let errorNumber = 0;
      let correctNumber = 0;
      let skipNumber = 0;
      this.data.forEach((item) => {
        if (item.answerStatus && this.dataInfo.linkAnswerType === 'QUIZ_MODE') {
          if (item.answerStatus === 'CORRECT') {
            correctNumber += 1;
          } else if (item.answerStatus === 'ERROR') {
            errorNumber += 1;
          } else if (item.answerStatus === 'SKIP') {
            skipNumber += 1;
          }
        } else if (this.countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)) {
          correctNumber += 1;
        } else {
          errorNumber += 1;
        }
      });
      return { correctNumber, errorNumber, skipNumber };
    },
  },

  methods: {
    transformStringToDelta,
    /**
     * 获取评分
     */
    getRateValue(score) {
      if (score > 0 && score < 16) {
        return 1;
      }
      if (score >= 18 && score < 79) {
        return 2;
      }
      if (score > 80) {
        return 3;
      }
      return 0;
    },
    /**
     * 计算平均分
     */
    getTotalAverageScore(scores) {
      if (!scores || scores.length === 0) {
        return 0;
      }
      return Math.ceil(scores.reduce((a, b) => a + b, 0) / scores.length);
    },
    countanswerStatus(questionType, answerStatus, errorNumber) {
      const oldQuestionType = [
        'CHOICE',
        'CONNECTION',
        'PICTURE_BOOK',
        'BLANK_FILLING',
        'EXPLAN_CHOICE',
        'ARTICLE_FILLING',
        'CLICK_SELECT',
        'READING_QUESTION',
        'SORTING_QUESTION',
        'WRITE_QUESTION',
        'MATH_FILLING',
      ];
      const newQuestionType = [
        'AUTO_WRITE',
        'AUTO_SORTING',
        'AUTO_CHOICE',
        'AUTO_FILLING',
        'AUTO_MATCHING',
        'AUTO_INPUT',
        'AUTO_INPUT_FILLING',
        'AUTO_READING',
        'AUTO_ADV_CHOICE',
      ];
      let bol = true;

      if (newQuestionType.includes(questionType)) {
        bol = answerStatus === 'CORRECT';
      }
      if (oldQuestionType.includes(questionType)) {
        bol = errorNumber === 0;
      }
      return bol;
    },
    explainResolution(id) {
      this.questionId = this.questionId === id ? '' : id;
      this.$emit('explainResolution', id);
    },
    getContainer() {
      return document.body;
    },
    initData(uuid, k) {
      const targetQuestion = this.checkData
        ?.find((item) => item.uuid === this.dataInfo?.linkId)
        ?.exercises?.find((item) => item.uuid === uuid);

      if (targetQuestion) {
        this.initCompont(targetQuestion, k);
      }
    },
    initCompont(data, k) {
      console.log(data.type);
      const o = {
        title: '做一做',
        type: '复习巩固',
        id: 'Segment1',
        status: false,
        cover: '/demo/segment-cover-1.png',
      };
      let typeObj = {};
      switch (data.type) {
        case 'PICTURE_BOOK':
          typeObj = this.pictureBookData(data);
          break;
        case 'READING_QUESTION':
          typeObj = this.readingQuestion(data);
          break;
        case 'CHOICE':
          typeObj = this.choiceData(data);
          break;
        case 'BLANK_FILLING':
          typeObj = this.blankFillingData(data);
          break;
        case 'CONNECTION':
          typeObj = this.connectionData(data);
          break;
        case 'SUBJECTIVE':
          typeObj = this.subjective(data);
          break;
        case 'EXPLAN_CHOICE':
          typeObj = this.explan(data);
          break;
        case 'GENERAL_SUBJECTIVE':
          typeObj = this.generalSubjective(data);
          break;
        case 'CLICK_SELECT':
          typeObj = this.pointSelect(data);
          break;
        case 'WRITE_QUESTION':
          typeObj = this.writeChar(data);
          break;
        case 'ARTICLE_FILLING':
          typeObj = this.articleFilling(data);
          break;
        case 'SORTING_QUESTION':
          typeObj = this.sort(data);
          break;
        case 'AUTO_FILLING':
          typeObj = this.autoFillingData(data);
          break;
        case 'AUTO_SORTING':
          typeObj = this.autoSortingData(data);
          break;
        case 'AUTO_WRITE':
          typeObj = this.autoWriteData(data);
          break;
        case 'AUTO_CHOICE':
        case 'AUTO_ADV_CHOICE':
          typeObj = this.autoChoiceData(data);
          break;
        case 'AUTO_MATCHING':
          typeObj = this.ligatureData(data);
          break;
        case 'AUTO_INPUT':
          typeObj = this.typewriteData(data);
          break;
        case 'AUTO_INPUT_FILLING':
          typeObj = this.typewriteFillData(data);
          break;
        case 'AUTO_READING':
          typeObj = this.newReadingQuestions(data);
          break;
        case 'READ_BOOK':
          typeObj = this.makeReader(data);
          break;
        default:
          break;
      }
      this.errorInfoData = null;
      this.correctInfoData = null;
      let errorOptions = null;
      let correctOptions = null;
      let errorNodes = [];
      let correctNodes = [];
      if (data.type === 'CHOICE') {
        errorOptions = Number(k.errorOptions || k.correctOptions);
        correctOptions = Number(k.correctOptions);
      } else if (data.type === 'EXPLAN_CHOICE') {
        errorOptions = parseInt(k.userAnswer, 10);
        correctOptions = k.correctOptions ? k.correctOptions?.charCodeAt() - 65 : '';
        if (Object.is(errorOptions, NaN)) {
          this.showError = false;
        }
      } else if (data.type === 'PICTURE_BOOK' || data.type === 'READING_QUESTION') {
        const obj = {
          audio: k.readRecording,
          score: k.readScore,
        };
        errorOptions = [obj];
      } else if (data.type === 'WRITE_QUESTION') {
        const obj = {
          position: 'right',
          finished: true,
          url: k.answerPictures && k.answerPictures[0],
        };
        errorOptions = [obj];
        correctOptions = [obj];
      } else if (data.type === 'AUTO_WRITE') {
        const obj = {
          position: 'right',
          finished: true,
          url: k.answerPictures && k.answerPictures[0],
        };
        // errorOptions = [obj];
        correctOptions = obj;
      } else if (data.type === 'AUTO_INPUT') {
        // eslint-disable-next-line no-unused-expressions
        errorOptions === k.errorOptions ?? k.userAnswer;
        correctOptions = k.correctOptions;
        console.log(k.correctOptions);
      } else if (data.type === 'AUTO_INPUT_FILLING') {
        const error = JSON.parse(k.errorOptions ?? k.userAnswer) || [];
        const correct = JSON.parse(k.correctOptions) || [];
        errorOptions = error;
        correctOptions = correct;
      } else if (data.type === 'AUTO_READING') {
        errorOptions = {
          audio: k.readRecording,
          score: k.readScore,
        };
        correctOptions = errorOptions;
      } else {
        console.log(data.type);
        errorOptions = JSON.parse(k.errorOptions || k.correctOptions);
        correctOptions = JSON.parse(k.correctOptions);
      }
      errorNodes = [{ ...typeObj, review: true, showAnswer: true, value: errorOptions }];
      correctNodes = [{ ...typeObj, review: true, showAnswer: true, value: correctOptions }];
      this.errorInfoData = [{ ...o, nodes: errorNodes }];

      this.correctInfoData = [{ ...o, nodes: correctNodes }];
      console.log('错误答案', JSON.stringify(this.errorInfoData));
      console.log('正确答案', JSON.stringify(this.correctInfoData));
      this.showInfo = this.showInfo === data.uuid ? '' : data.uuid;
    },

    // 绘本
    pictureBookData(data) {
      return {
        type: 'pictureBook',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: [
          {
            id: data.uuid,
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
          },
        ],
      };
    },
    // 朗读题 READING_QUESTION
    readingQuestion(data) {
      return {
        type: 'pictureSpeak',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          id: data.uuid,
          picture: data.contentOption.picture,
          audio: data.contentOption.bookAudio,
          text: data.contentOption.text,
          pinyins: data.contentOption.pinyins,
        },
      };
    },
    // 选择题
    choiceData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        type: 'choice',
        payload: {
          audio: data.contentOption.audio,
          picture: data.contentOption.picture,
          options: data.contentOption.options,
          answer: data.contentOption.answer,
        },
      };
    },
    // 选词填空
    blankFillingData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'blankFilling',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    // 连线
    connectionData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        type: 'connection',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    //
    subjective(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'subjective',
        payload: {
          picture: data.contentOption.picture,
          status: 'finished',
          preview: true,
          task: data.contentOption.task,
          steps: data.contentOption.steps,
          homework: {},
        },
      };
    },
    // 带解析的选择题
    explan(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          pictures: [data.contentOption.picture],
          explan: data.contentOption.explan,
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 单选题
    explanSingle(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        // payload: data.contentOption,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          explan: data.contentOption.explan,
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 通用主观题
    generalSubjective(data) {
      return {
        type: 'generalSubjective',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: {
          status: 'finished',
          question: Object.assign(data.contentOption, { title: data.title }),
          // answer: {},
        },
      };
    },
    // 点选题
    pointSelect(data) {
      return {
        type: 'pointSelect',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 写字练习
    writeChar(data) {
      return {
        type: 'writeChar',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: Object.assign(data.contentOption, { clearDefaultShow: false }),
      };
    },
    // 文章补全题
    articleFilling(data) {
      return {
        type: 'articleCompletion',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 排序
    sort(data) {
      return {
        type: 'sort',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        payload: data.contentOption,
      };
    },
    // 自动补全题
    autoFillingData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'completion',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 自动排序
    autoSortingData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'sequence',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 自动写字
    autoWriteData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'writeCharV3',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    // 配对题
    ligatureData(data) {
      const { text } = data?.contentOption?.autoLeft || {};
      const options = data?.contentOption?.autoRight;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'ligature',
        payload: {
          question: { ...data?.contentOption?.autoLeft, text: { pinyins: text?.pinYins, value: text?.value } },
          operation: {
            ...options,
            begin: options.begin?.map((i) => {
              if (i.type === 'picture') {
                return {
                  ...i,
                  type: 'pic',
                };
              }
              return {
                ...i,
              };
            }),
            end: options.end?.map((i) => {
              if (i.type === 'picture') {
                return {
                  ...i,
                  type: 'pic',
                };
              }
              return {
                ...i,
              };
            }),
          },
        },
      };
    },
    // 打字题-全部
    typewriteData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'typewrite',
        payload: {
          question: {
            ...data?.contentOption?.autoLeft,
            type: data?.contentOption?.autoLeft.type === 'picture' ? 'pic' : data?.contentOption?.autoLeft.type,
          },
          operation: data?.contentOption?.autoRight,
        },
      };
    },

    // 打字填空题
    typewriteFillData(data) {
      const d = JSON.parse(JSON.stringify(data));
      const questionData = { ...d?.contentOption?.autoLeft, showPy: d?.contentOption?.autoLeft?.show_py };
      delete questionData.show_py;
      const operationData = { ...d?.contentOption?.autoRight };
      delete operationData.pinyinList;
      return {
        id: d.uuid,
        title: {
          content: d.title,
          audio: d.audio,
          enContent: d.enTitle,
          enAudio: d.enAudio,
        },
        tag: d.tag,
        type: 'typewriteFill',
        payload: {
          question: {
            ...questionData,
          },
          operation: operationData,
        },
      };
    },
    // 绘本朗读2.0
    makeReader(data) {
      return {
        type: 'reader',
        id: data.uuid,
        pageSpan: 1,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        payload: [
          {
            id: data.uuid,
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
          },
        ],
      };
    },
    // 新朗诵题
    newReadingQuestions(data) {
      console.log(data);
      const questionData = { ...data?.contentOption?.autoLeft };
      const operationData = { ...data?.contentOption?.autoRight };
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'recitation',
        payload: {
          question: {
            ...questionData,
          },
          operation: operationData,
        },
      };
    },
    // 自动选择
    autoChoiceData(data) {
      const { text } = data?.contentOption?.autoLeft;
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
          enContent: data.enTitle,
          enAudio: data.enAudio,
        },
        tag: data.tag,
        type: 'choiceV3',
        payload: {
          question: {
            ...data?.contentOption?.autoLeft,
            text: { pinyins: text?.pinYins, value: text?.value },
            isSenior: data.type === 'AUTO_ADV_CHOICE',
          },
          operation: data?.contentOption?.autoRight,
        },
      };
    },
    onClose() {
      this.showInfo = '';
      this.errorInfoData = null;
      this.correctInfoData = null;
    },
    onShowInfo(uuid, k) {
      this.initData(uuid, k);
    },
    classOutInTime(value) {
      let theTime = Number(value);
      let middle = 0;
      let hour = 0;
      if (theTime > 60) {
        middle = parseInt(theTime / 60, 10);
        theTime = parseInt(theTime % 60, 10);
        if (middle > 60) {
          hour = parseInt(middle / 60, 10);
          middle = parseInt(middle % 60, 10);
        }
      }
      let result = '';
      if (parseInt(theTime, 10) > 0) {
        result = `${parseInt(theTime, 10)}s`;
      }
      if (middle > 0) {
        result = `${parseInt(middle, 10)}m${result}`;
      }
      if (hour > 0) {
        result = `${parseInt(hour, 10)}h${result}`;
      }
      return result;
    },

    aiAnalysisHandle(data) {
      if (!data) {
        return '无';
      }
      const datas = JSON.parse(JSON.stringify(data));
      let strArr = [];
      datas.forEach((item) => {
        strArr.push(
          `${item.initialsErrorMessage || item.vowelErrorMessage ? `${item.text}：` : ''}${
            item.initialsErrorMessage ? `声母：${item.initialsErrorMessage},` : ''
          }${item.vowelErrorMessage ? `韵母：${item.vowelErrorMessage};` : ''}`,
        );
      });
      strArr = Array.from(new Set(strArr));
      return strArr.join(' ');
    },
    questionType(type) {
      const TYPE = [
        { type: 'CHOICE', name: '选择题' },
        { type: 'AUTO_ADV_CHOICE', name: '高级选择题' },
        { type: 'CONNECTION', name: '连线题' },
        { type: 'EXPLAN_CHOICE', name: '带解析的选择题' },
        { type: 'BLANK_FILLING', name: '选词填空题' },
        { type: 'ARTICLE_FILLING', name: '文章填空题' },
        { type: 'PICTURE_BOOK', name: '绘本朗读' },
        { type: 'SORTING_QUESTION', name: '排序题' },
        { type: 'WRITE_QUESTION', name: '书写题' },
        { type: 'CLICK_SELECT', name: '点选题' },
        { type: 'SUBJECTIVE', name: '主观题' },
        { type: 'GENERAL_SUBJECTIVE', name: '通用主观题' },
        { type: 'SINGLE_CHOICE', name: '单选题' },
        { type: 'READING_QUESTION', name: '朗读题' },
        { type: 'AUTO_MATCHING', name: '配对题' },
        { type: 'AUTO_CHOICE', name: '选择题' },
        { type: 'AUTO_FILLING', name: '填空题' },
        { type: 'AUTO_WRITE', name: '写字练习题' },
        { type: 'AUTO_SORTING', name: '排序题' },
        { type: 'AUTO_INPUT_FILLING', name: '打字填空题' },
        { type: 'AUTO_INPUT', name: '打字题' },
        { type: 'AUTO_READING', name: '朗诵题' },
        { type: 'READ_BOOK', name: '绘本朗读题2.0' },
      ];
      return TYPE.filter((item) => type === item.type)[0]?.name;
    },
    onExpand() {
      this.is_expand = !this.is_expand;
    },
    audioPlay(value) {
      if (this.parentaudio === value) {
        // this.$parent.clearAudio();
        this.$emit('clearAudio');
        // this.isAudio = false;
        return;
      }
      // this.isAudio = true;
      this.$emit('audioPlay', { audio: value, isAudio: this.isAudio });
    },
  },
};
</script>
<style lang="less"></style>
<style lang="less" scoped>
.report-main {
  padding: 10px 0 0;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  .showInfo {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-content: center;
    left: 0;
    top: 0;
    z-index: 1000;
  }
  .showInfo-main {
    width: 800px;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 20px;
    .title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      p {
        font-size: 16px;
        color: #333;
      }
    }
    .backfill {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 2px solid #f2f2f2;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px;
      .backfill-main {
        width: 580px;
      }
    }
    .correct p {
      color: #27ae60;
    }
  }
  .report-header {
    padding: 0 20px;
    .subtract-title {
      display: flex;
      // justify-content: flex-start;
    }
    .answer-time {
      display: flex;
      justify-content: space-between;
      i {
        margin: 0 4px;
      }
      & > div:nth-child(2) {
        margin-left: 20px;
      }
    }
    .subtract {
      width: 50px;
      height: 50px;
      background: #ecf8ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  }
  .readRecording {
    &:hover {
      cursor: pointer;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  p {
    font-size: 18px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      // margin-right: 40px;
    }
  }
  .explain-title {
    padding: 7px 12px;
    font-size: 14px;
    color: #333;
    background-color: #f1faf5;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 12px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      color: #333;
      margin-bottom: 0;
    }
  }
  .explain-main {
    p {
      font-size: 14px;
      color: #333;
    }
  }
  .explain-video {
    width: 160px;
    height: 90px;
    margin: 0 10px;
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .fraction-main {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .answers-overview {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    .all-answers {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #333;
      width: 120px;
    }
    .answers {
      font-size: 12px;
      line-height: 36px;
      color: #666666;
      flex: 1;
      span {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        font-size: 20px;
        font-weight: 600;
        color: #009cff;
        background-color: #ecf8ff;
        margin-right: 10px;
      }
      .error {
        color: #eb5757;
        background-color: rgba(235, 87, 87, 0.1);
      }
      .correct {
        color: #27ae60;
        background-color: rgba(111, 207, 151, 0.1);
      }
      .skip {
        color: rgba(51, 51, 51, 0.6);
        background-color: #f2f2f2;
      }
    }
  }
  .topics-list {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    .topics-item {
      min-width: 65px;
      font-size: 14px;
      color: #000;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-self: center;
      & > div {
        text-align: center;
        outline: 1px solid #f2f2f2;
        padding: 5px 16px;
      }
      & > .topics-main {
        padding: 5px 8px;
      }
      & > div:nth-child(2) {
        height: 50px;
        p {
          font-size: 14px;
          color: #000;
          text-align: center;
          line-height: 40px;
          margin-bottom: 0;
        }
      }
      & > div:nth-child(3) {
        height: 50px;
      }
    }
    .topics-title {
      width: 65px;
      & > div:nth-child(2) {
        height: 50px;
        p {
          font-size: 14px;
          color: #000;
          text-align: center;
          line-height: 40px;
          margin-bottom: 0;
        }
      }
    }
  }
  .fraction {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .report-expand {
    .expand-btn {
      width: 100%;
      height: 42px;
      padding-right: 10px;
      line-height: 42px;
      text-align: right;
      // border-radius: 10px;
      font-size: 16px;
      color: #009cff;
      background-color: #ecf8ff;
      margin-bottom: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report-list {
    padding: 10px 20px 0;
    & > div:last-child .report-item {
      border: none;
    }
    .report-item {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    }
    .report-unit {
      display: flex;
      justify-content: flex-start;
      & > div {
        width: 100%;
      }
      & > img {
        width: 30px;
        height: 30px;
        margin: 20px 20px 0 0;
      }
      .item-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 18px 20px;
        background: #f1faf5;
        border-radius: 12px;
      }
      .item-time {
        font-weight: 600;
        color: #009cff;
      }
      .item-error {
        font-weight: 600;
        color: #eb5757;
      }
      .title-error {
        background: #fdeeee;
      }
      .title-skip {
        background: rgba(242, 242, 242, 1);
      }
      .show-info {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 12px;
        color: #009dff;
        border: 1px solid #009dff;
        border-radius: 8px;
        margin-bottom: 20px;
      }
    }
    .item-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        max-width: 80%;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        padding: 2px 5px;
        border-radius: 10px;
        border: 1px solid #eee;
        margin-left: 5px;
      }
    }
    .item-audio {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      audio {
        height: 22px;
        margin: 0 10px;
      }
    }
    .item-ai {
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;
      span {
        padding: 2px 8px;
        border-radius: 10px;
        background: #f7f7f7;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>

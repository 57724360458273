<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div v-if="formInfoData.upComingTypeValues === 'Done' || formInfoData.upComingTypeValues === 'SendMe'">
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>报告阶段:</span>
          <p>{{ formInfoData.progress.split('-')[0] + '-' + formInfoData.progress.split('-')[1] }}</p>
          <!-- <p>{{ formInfoData.progress.substring(0, formInfoData.progress.indexOf('-')) }}</p> -->
        </div>
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>当前学习进度:</span>
          <p>{{ formInfoData.progress }}</p>
        </div>
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>学情打分:</span>
          <p><a-rate :default-value="formInfoData.score" class="start" disabled /></p>
        </div>
        <div class="form-desc1" v-if="formInfoData.listeningLevel">
          <span class="red-icon">*</span>
          <span>听力水平:</span>
          <p>{{ formInfoData.listeningLevel }}</p>
        </div>
        <div class="form-desc1" v-if="formInfoData.readingLevel">
          <span class="red-icon">*</span>
          <span>认读能力:</span>
          <p>{{ formInfoData.readingLevel }}</p>
        </div>
        <div class="form-desc1" v-if="formInfoData.speakingLevel">
          <span class="red-icon">*</span>
          <span>口语水平:</span>
          <p>{{ formInfoData.speakingLevel }}</p>
        </div>
        <div class="form-desc1" v-if="formInfoData.writingLevel">
          <span class="red-icon">*</span>
          <span>书写能力:</span>
          <p>{{ formInfoData.writingLevel }}</p>
        </div>
        <div class="form-desc1">
          <span class="red-icon">*</span>
          <span>{{ formInfoData.writingLevel ? '老师想说的话:' : '老师寄语:' }}</span>
          <div class="remake" v-html="formInfoData.remake" style="margin-left: 8px"></div>
        </div>
      </div>
      <div v-else>
        <div class="form-desc1 reportPhase">
          <div class="form-label">
            <span>报告阶段: </span>
          </div>
          <div class="form-reason">
            {{ formInfoData.progress.split('-')[0] + '-' + formInfoData.progress.split('-')[1] }}
          </div>
        </div>
        <div class="form-desc1 reportPhase">
          <div class="form-label">
            <span>当前学习进度: </span>
          </div>
          <div class="form-reason">{{ formInfoData.progress }}</div>
        </div>
        <a-form :form="studyTaskForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="学情打分">
            <a-rate
              v-decorator="['score', { rules: [{ required: true, message: '请输入学情打分' }] }]"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="听力水平" v-if="!$route.query.courseSectionId">
            <a-textarea
              v-decorator="[
                'listeningLevel',
                {
                  rules: [
                    { required: true, message: '请输入听力水平' },
                    { max: 150, message: '听力水平多150字', trigger: 'change' },
                  ],
                },
              ]"
              placeholder="听力水平"
              :auto-size="{ minRows: 2 }"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="认读能力" v-if="!$route.query.courseSectionId">
            <a-textarea
              v-decorator="[
                'readingLevel',
                {
                  rules: [
                    { required: true, message: '请输入认读能力' },
                    { max: 200, message: '认读能力多200字', trigger: 'change' },
                  ],
                },
              ]"
              placeholder="认读能力"
              :auto-size="{ minRows: 2 }"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="口语水平" v-if="!$route.query.courseSectionId">
            <a-textarea
              v-decorator="[
                'speakingLevel',
                {
                  rules: [
                    { required: true, message: '请输入口语水平' },
                    { max: 200, message: '口语水平多200字', trigger: 'change' },
                  ],
                },
              ]"
              placeholder="口语水平"
              :auto-size="{ minRows: 2 }"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="书写能力" v-if="!$route.query.courseSectionId">
            <a-textarea
              v-decorator="[
                'writingLevel',
                {
                  rules: [
                    { required: true, message: '请输入书写能力' },
                    { max: 200, message: '书写能力多200字', trigger: 'change' },
                  ],
                },
              ]"
              placeholder="书写能力"
              :auto-size="{ minRows: 2 }"
              @change="oncheckRequired"
            />
          </a-form-item>
          <a-form-item label="老师寄语">
            <QuillEditor
              v-decorator="[
                'remake',
                {
                  rules: [{ required: true, message: '请输入老师寄语' }],
                },
              ]"
              @getRemake="getRemake"
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="concat-wrap" v-if="formInfoData.knowledge">
        <div class="title knowledgeTitle" @click="onShowKnowledge">阶段知识点</div>
        <div class="knowledge" ref="knowledge">
          <div v-html="formInfoData.knowledge"></div>
        </div>
      </div>
    </div>

    <div class="concat-wrap" v-if="formInfoData.relatedClass && formInfoData.relatedClass.length !== 0">
      <div class="title">关联班级</div>
      <RelatedClass
        class="concat-con"
        v-for="(classItem, index) in formInfoData.relatedClass"
        :classItem="classItem"
        :key="index"
        @onGetStudentId="onGetStudentId"
      >
      </RelatedClass>
    </div>
    <div v-if="formInfoData.studentArray && formInfoData.studentArray.length !== 0">
      <div class="title">
        <span>学生</span>
      </div>
      <div class="student-wrap">
        <div v-for="item in formInfoData.studentArray" :key="item.id" class="student-item">
          <div :class="{ cursor: !formInfoData.showcourseSection }" @click="onStudentId(item)" style="display: flex">
            <img :src="item.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ item.name }} ({{ item.code }})>
              </p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';
import QuillEditor from './QuillEditor.vue';
import RelatedClass from './RelatedClass.vue';

export default {
  components: { RelatedClass, QuillEditor, AssociatedTasks },
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      studyTaskForm: this.$form.createForm(this),
      isRequired: false,
      showKnowledge: false,
    };
  },
  methods: {
    getRemake(data) {
      this.studyTaskForm.setFieldsValue({ remake: data });
      this.oncheckRequired();
    },
    onShowKnowledge() {
      this.showKnowledge = !this.showKnowledge;
      this.$refs.knowledge.style.height = this.showKnowledge ? 'auto' : '200px';
    },
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.id });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    // 检查数据
    oncheckRequired() {
      this.$nextTick(() => {
        this.$emit('onStudyReport', this.studyTaskForm);
      });
    },
  },
};
</script>

<style lang="less">
.remake {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
}
</style>
<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: space-between;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  p {
    margin-left: 8px;
  }
}

.reportPhase {
  display: flex;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}

.knowledge {
  // height: 200px;
  // overflow-y: auto;
}
.knowledgeTitle span {
  font-size: 16px;
  font-weight: 400;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.icon-starlight {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star_ed.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
.icon-star {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
/deep/ .ant-form label {
  font-size: 16px !important;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
</style>

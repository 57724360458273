<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span>
            </div>
            <div>
              【完成方式】电话/文字沟通<br />
              【完成时效】30天<br />
              【操作步骤】<br />
              1、沟通前：<br />
              a. 花果查看学生不续费/退费原因，查看前期沟通记录<br />
              2、沟通中： <br />
              a. 核对流失情况<br />
              b.把控意向度（在意点、复课意向）<br />
              c.解决&记录家长异议<br />
              3、沟通后： <br />
              a. 及时填写跟进记录，记录沟通的重要信息<br />
              b. 优先做风险管理，有问题及时跟进反馈<br />
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }">
          {{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm') }}
          {{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}
        </span>
      </div>
      <div class="form-desc" style="padding-left: 27px">
        请及时跟进该流失学员的情况哦~
        <a-button type="link" size="large" @click="getNotRenewInfo" :loading="notRenewLoading">
          <span style="color: #04cb94; text-decoration: underline; cursor: pointer">学员流失记录</span>
        </a-button>
      </div>

      <div style="margin-top: 15px">
        <div class="title">
          <span>关联学生</span>
        </div>
        <div class="student-wrap">
          <div
            class="student-item"
            :class="{ cursor: !formInfoData.showcourseSection }"
            @click="onStudentId(formInfoData.student)"
          >
            <img :src="formInfoData.student.headImageUrL || defaultImg" alt="" class="handler-img" />
            <div>
              <p class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
                {{ formInfoData.student.name }} ({{ formInfoData.student.code }})>
              </p>
              <p>学生</p>
            </div>
          </div>
          <div style="display: flex">
            <AssociatedTasks
              type="other"
              :totalNum="formInfoData.currentStudentOtherTaskNumber"
              :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
              style="margin-right: 10px"
            />
            <AssociatedTasks
              type="familyOther"
              :totalNum="formInfoData.otherStudentTaskNumber"
              :taskList="formInfoData.otherStudentTasks || []"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import AssociatedTasks from './AssociatedTasks.vue';
import { getStudentNotRenewList } from '../../../../apps/cp-class-advisor-center/src/api/students';
import { openNewTab } from '../../../../apps/cp-class-advisor-center/src/utils/util';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { AssociatedTasks },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      studyTaskForm: this.$form.createForm(this),
      isRequired: false,
      notRenewLoading: false,
    };
  },
  methods: {
    async getNotRenewInfo() {
      this.notRenewLoading = true;

      const { student } = this.formInfoData;

      const params = {
        lossRefundType: 'NOT_RENEW',
        name: student.code,
        startDateTime: null,
        endDateTime: null,
        dateRange: [],
        subject: student.subject,
        completeDateRange: [],
        completionStatus: 'NORMAL',
      };

      const { data } = await getStudentNotRenewList(params);

      const record = data.content?.[0];
      if (record) {
        openNewTab('/lostRefundEdit?id=' + record.uuid);
      } else {
        openNewTab(`/lostRefundEdit?code=${student.code}&subject=${student.subject}`);
      }

      this.notRenewLoading = false;
    },

    hrefLoseDetail() {
      if (this.formInfoData.lossRefundId) {
        this.$router.push({ path: '/lostRefundDetail', query: { id: this.formInfoData.lossRefundId } });
      } else {
        this.$message.warning('没有关联的流失详情');
      }
    },
    // 打开关联任务的详情
    openConTaskDeatil(detailObj) {
      this.$emit('openConTaskDeatil', detailObj);
    },

    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
  },
};
</script>

<style lang="less" scoped>
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}

.form-desc {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
  padding-left: 28px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}
.reportPhase {
  display: flex;
}

.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  cursor: pointer;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
.takeoverTitle {
  font-size: 16px;
  margin-bottom: 10px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .student-name {
    color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
</style>
